import axios from './axios'
import Swal from 'sweetalert2'
import * as localstorage from './LocalStorage'

//{{url}}/api/transaction/getnbtransaction?period=month
export async function getnbtransaction (period, kioskid) {
  if (
    localstorage.loadAccess() !== undefined &&
    localstorage.loadAccess() !== null
  ) {
    let res = await axios.get(``).catch(err => {
      return undefined
    })

    return res
  }
}
export async function getnbusers () {
  if (
    localstorage.loadAccess() !== undefined &&
    localstorage.loadAccess() !== null
  ) {
    let res = await axios
      .get(`/user/getnbuser`)
      .then(data => {
        return data
      })
      .catch(err => {
        return undefined
      })

    return res
  }
}
export async function getStock () {
  if (
    localstorage.loadAccess() !== undefined &&
    localstorage.loadAccess() !== null
  ) {
    let res = await axios
      .get(`/stock/getsumstock`)
      .then(data => {
        //console.log(data, "data");
        return data
      })
      .catch(err => {
        return undefined
      })

    return res
  }
}
export async function checkQuantity () {
  if (
    localstorage.loadAccess() !== undefined &&
    localstorage.loadAccess() !== null
  ) {
    let res = await axios
      .get(`/category/checkquantity`)
      .then(data => {
        //console.log(data, "data");
        return data
      })
      .catch(err => {
        return undefined
      })

    return res
  }
}

export async function getinfo (categoryid, pdvid) {
  if (
    localstorage.loadAccess() !== undefined &&
    localstorage.loadAccess() !== null
  ) {
    var filtre = ''
    if (categoryid && categoryid !== 'all') {
      filtre += '?categoryid=' + categoryid
    }
    filtre = filtre + (filtre ? '&' : '?')
    if (pdvid && pdvid !== 'all') {
      filtre += 'pdvid=' + pdvid
    }
    let res = await axios
      .get(`/dashboard/get` + filtre)
      .then(data => {
        return data
      })
      .catch(err => {
        return undefined
      })

    return res.data.data
  }
}
export async function getstatistics (categoryid, pdvid) {
  if (
    localstorage.loadAccess() !== undefined &&
    localstorage.loadAccess() !== null
  ) {
    var filtre = ''
    if (categoryid && categoryid !== 'all') {
      filtre += '?categoryid=' + categoryid
    }
    filtre = filtre + (filtre ? '&' : '?')
    if (pdvid && pdvid !== 'all') {
      filtre += 'pdvid=' + pdvid
    }
    let res = await axios
      .get(`/dashboard/getstatistics` + filtre)
      .then(data => {
        return data
      })
      .catch(err => {
        return undefined
      })

    return res.data.data
  }
}
