import React, { useState } from 'react'
import { useLayoutEffect, useEffect } from 'react'
import Swal from 'sweetalert2'
import Form from 'react-bootstrap/Form'
import editprofileusr from './../../assets/image/editprofileusr.png'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Footballloader from '../loader/footballloader'
import * as userservice from '../../services/UserService'
import * as pdvservice from '../../services/PdvService'
import { useParams } from 'react-router'

const Editpdv = () => {
  const { id } = useParams()
  const [loading, setLoading] = useState(true)
  const [validated, setValidated] = useState(false)
  const [fullname, setFullname] = useState('')
  const [pdvname, setPdvname] = useState('')
  const [role, setRole] = useState([])
  const [state, setState] = useState({
    idpdv: '',
    password: '',
    cnewpassword: '',
    newpassword: ''
  })
  const getpdv = async () => {
    const var_usrpdv = await userservice.getotheruser(id)

    if (var_usrpdv && var_usrpdv !== null && var_usrpdv !== undefined) {
      setFullname(var_usrpdv.firstname + '  ' + var_usrpdv.lastname)
      const var_pdv = await pdvservice.findbyusr(var_usrpdv.id)
      if (var_pdv && var_pdv !== null && var_pdv !== undefined) {
        setPdvname(var_pdv.name)
      }
    }
  }
  useEffect(() => {
    getpdv()
  }, [])

  useLayoutEffect(() => {
    if (id) {
      setState({ ...state, idpdv: id })
      setLoading(false)
    }
  }, [])
  const changeCreds = event => {
    setState({ ...state, [event.target.name]: event.target.value })
  }

  const handleSubmit = async event => {
    event.preventDefault()
    event.stopPropagation()

    setValidated(true)
    const form = event.currentTarget
    if (form.checkValidity() === true) {
      if (state.cnewpassword === state.newpassword) {
        setLoading(true)
        const res = await userservice.updatepasswordpdv(state)
        setLoading(false)
        if (res.data.code === 0) {
          Swal.fire({
            title: 'Succès!',
            text: 'Le mot de passe mis à jour avec succès',
            icon: 'success',
            confirmButtonText: 'OK'
          }).then(result => {
            window.location.href = '/#/page/users'
          })
        } else {
          Swal.fire({
            title: 'Erreur!',
            text:
              'Impossible de mettre à jour le mot de passe.Vérifier les informations saisies',
            icon: 'error',
            confirmButtonText: 'OK'
          })
        }
      } else {
        Swal.fire({
          title: 'Attention!',
          text: 'La confirmation du nouveau mot de passe est incorrecte',
          icon: 'warning',
          confirmButtonText: 'OK'
        })
      }
    }
  }
  return (
    <div>
      <div className='row justify-content-md-center'>
        <Modal centered show={loading}>
          <Footballloader />
        </Modal>
        <div class='row container d-flex justify-content-center  backcolor'>
          <div class='col-4 bg-c-lite-green user-profile rounded-lg'>
            <div class='card-block text-center text-white'>
              <div class='m-b-25'>
                <img
                  src={editprofileusr}
                  class='img-radius'
                  alt='User-Profile-Image'
                />
              </div>
              <h6 class='f-w-600'>{fullname}</h6>
              <h6 class='f-w-600'>{pdvname}</h6>

              {role.map(option => (
                <p key={option}>{option}</p>
              ))}
              <i class=' mdi mdi-square-edit-outline feather icon-edit m-t-10 f-16'></i>
            </div>
          </div>
          <div class='col-sm-8'>
            <div class='card-block'>
              <h6 class='m-b-20 p-b-5 b-b-default f-w-600'>
                Modifier le mot de passe du point de vente
                
                <br />
              </h6>
              <br />
              <div class='row justify-content-md-center'>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <Form.Group
                    as={Row}
                    className='mb-3'
                    controlId='validationCustom01'
                  >
                    <Form.Label class='f-w-600' column sm='8'>
                      Le nouveau mot de passe
                    </Form.Label>
                    <Col sm='8'>
                      <Form.Control
                        name='newpassword'
                        minLength={8}
                        value={state.newpassword}
                        onChange={event => changeCreds(event)}
                        required
                        type='password'
                        placeholder='nouveau mot de passe'
                      />
                      <Form.Control.Feedback type='invalid'>
                        Le nouveau mot de passe est obligatoire(au moins 8
                        caractères)
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    className='mb-3'
                    controlId='validationCustom01'
                  >
                    <Form.Label class='f-w-600' column sm='8'>
                      Confirmez Le nouveau mot de passe
                    </Form.Label>
                    <Col sm='8'>
                      <Form.Control
                        name='cnewpassword'
                        minLength={8}
                        value={state.cnewpassword}
                        onChange={event => changeCreds(event)}
                        required
                        type='password'
                        placeholder='nouveau mot de passe'
                      />
                      <Form.Control.Feedback type='invalid'>
                        Confirmez Le nouveau mot de passe est obligatoire(au
                        moins 8 caractères)
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    className='mb-3'
                    controlId='validationCustom02'
                  >
                    <Form.Label class='f-w-600' column sm='8'>
                      Votre mot de passe
                    </Form.Label>
                    <Col sm='8'>
                      <Form.Control
                        name='password'
                        minLength={6}
                        value={state.password}
                        onChange={event => changeCreds(event)}
                        required
                        type='password'
                        placeholder='Votre mot de passe'
                      />
                      <Form.Control.Feedback type='invalid'>
                        Votre mot de passe est obligatoire
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                  <Container>
                    <Row className='justify-content-md-center'>
                      <Col xxl lg='3'></Col>
                      <Col xxl lg='3'></Col>

                      <Col xxl lg='3'></Col>
                      <Col xxl lg='3'>
                        <Button className='btn btn-primary-red' type='submit'>
                          ok
                        </Button>
                      </Col>
                    </Row>
                  </Container>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Editpdv
