import React, { useState } from 'react'
import { useLayoutEffect, useEffect } from 'react'
import Swal from 'sweetalert2'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Footballloader from '../loader/footballloader'
import * as pdvservice from '../../services/PdvService'
import * as userservice from '../../services/UserService'
import * as categoryservice from '../../services/CategoryService'
import * as stockservice from '../../services/StockService'
import { getStock } from '../../services/StockService'
import { element } from 'prop-types'
import { useParams } from 'react-router'
import './form.css'
const Addpdv = () => {
  var datastock0 = []
  const [loading, setLoading] = useState(false)
  const [validated, setValidated] = useState(false)
  /*  const [stock, setStock] = useState({}) */
  const [datacategories, setDatacategories] = useState([])
  const [datastock, setDatastock] = useState([
    { categoryid: '', qtt: 0, oldqtt: 0, newqtt: 0, minqtt: 0 }
  ])
  const [statepdv, setStatepdv] = useState({
    isfree: false,
    name: '',
    address: '',
    telephone: '',
    enable: true
  })
  const [stateusr, setStateusr] = useState({
    firstname: '',
    lastname: '',
    email: '',
    address: '',
    username: '',
    password: '',
    telephone: null,
    enable: true,
    roles: 'pdv'
  })
  const changeCredspdv = event => {
    setStatepdv({ ...statepdv, [event.target.name]: event.target.value })
  }
  const changeCredsusr = event => {
    setStateusr({ ...stateusr, [event.target.name]: event.target.value })
  }
  const changedatastock = (event, id) => {
    updateqtt(id, event.target.value)
  }
  const updateqtt = (id, qtt) => {
    const indexToUpdate = datastock.findIndex(item => item.categoryid === id)
    const updateddatastock = [...datastock] // creates a copy of the array
    updateddatastock[indexToUpdate].qtt =
      qtt - updateddatastock[indexToUpdate].oldqtt
    updateddatastock[indexToUpdate].newqtt = qtt
    setDatastock(updateddatastock)
  }
  const getinfcategories = async () => {
    var res = await categoryservice.checkquantity()
    if (res) {
      let table = []
      for (let i = 0; i < res.length; i = i + 3) {
        let table01 = []
        for (let c = i; c < i + 3 && c < res.length; c++) {
          var obj = { categoryid: '', categoryname: '', oldqtt: 0, newqtt: 0 }
          obj.categoryid = res[c].id
          obj.name = res[c].name

          table01.push(res[c])
          datastock0.push({
            categoryid: res[c].id,
            qtt: 0,
            oldqtt: 0,
            newqtt: 0,
            minqtt: 0
          })
        }
        table.push(table01)
      }
      if (id) {
        const stockData = await getStock({ pdvid: id, size: 10 })
        // console.log(stockData)
        if (stockData?.data?.code === 0) {
          const stock = stockData?.data?.data?.stocks
          for (var c = 0; c < stock.length; c++) {
            const indexToUpdate = datastock0.findIndex(
              item => item.categoryid === stock[c].categoryId
            )
            //  console.log(stock)
            datastock0[indexToUpdate].oldqtt = stock[c].quantity
            datastock0[indexToUpdate].newqtt = stock[c].quantity
            datastock0[indexToUpdate].minqtt = stock[c].soldnumber
          }
          setDatacategories(table)
          //console.log(datastock0)
          setDatastock(datastock0)
        }
      }
    }
  }
  const handleSubmit = async event => {
    event.preventDefault()
    event.stopPropagation()

    setValidated(true)
    const form = event.currentTarget
    if (form.checkValidity() === true) {
      setLoading(true)
      var resupdate = []
      if (id) {
        for (const element of datastock) {
          if (element.qtt > 0) {
            resupdate = await stockservice.updateStock(
              id,
              element.categoryid,
              element.qtt
            )
          } else if (element.qtt < 0) {
            resupdate = await stockservice.decreaseStock(
              id,
              element.categoryid,
              Math.abs(element.qtt)
            )
          }
          //console.log(JSON.stringify(resaddstock))
        }
        setLoading(false)
        if (resupdate.code === 0) {
          Swal.fire({
            title: 'success!',
            text: 'Stock modifié avec succès',
            icon: 'success',
            confirmButtonText: 'OK'
          }).then(result => {
            window.location.href = '#/page/pdvs'
          })
        } else {
          Swal.fire({
            title: 'Error!',
            text: ' ',
            icon: 'error',
            confirmButtonText: 'OK'
          }).then(result => {
            window.location.href = '#/page/pdvs'
          })
        }
      } else {
        const resaddusr = await userservice.adduser(stateusr)

        if (resaddusr && resaddusr !== undefined && resaddusr !== null) {
          if (resaddusr.data.code === 0) {
            if (statepdv.telephone.length < 0) {
            }
            const resaddpdv = await pdvservice.addpdv(
              statepdv,
              resaddusr.data.data
            )
            if (resaddpdv !== undefined && resaddpdv !== null) {
              if (resaddpdv.data.code === 0) {
                for (let i = 0; i < datastock.length; i++) {
                  const resaddstock = await stockservice.addstock(
                    resaddpdv.data.data.id,
                    datastock[i].categoryid,
                    datastock[i].qtt
                  )
                  //console.log(JSON.stringify(resaddstock))
                }
                setLoading(false)
                Swal.fire({
                  title: 'success!',
                  text: 'Utilisateur ajouté avec succès',
                  icon: 'success',
                  confirmButtonText: 'OK'
                }).then(result => {
                  window.location.href = '#/page/pdvs'
                })
              } else {
                Swal.fire({
                  title: 'Error!',
                  text:
                    'une erreur(' + resaddpdv.data.code + ') se produit avec ',
                  icon: 'error',
                  confirmButtonText: 'OK'
                })
              }
            }
          } else {
            Swal.fire({
              title: 'Error!',
              text: 'une erreur(' + resaddusr.data.code + ') se produit avec ',
              icon: 'error',
              confirmButtonText: 'OK'
            })
          }
        } else {
          Swal.fire({
            title: 'Error!',
            text: 'une erreur  se produit avec ',
            icon: 'error',
            confirmButtonText: 'OK'
          })
        }
      }
    }
  }
  const getinfusers = async () => {
    if (id) {
      const user = await userservice.getUserDetails(id)

      if (user?.data?.code === 0) {
        setStatepdv({
          ...statepdv,
          name: user.data.data.name,
          userid: user.data.data.userid,
          address: user.data.data.address,
          telephone: user.data.data.telephone,
          enable: user.data.data.enable
        })
      }
    }
  }
  /*   const getStocks = async () => {
    const stockData = await getStock({ pdvid: id, size: 10 })
    if (stockData?.data?.code === 0) {
      setStock(stockData?.data.data)
    }
  } */
  const { id } = useParams()
  useEffect(() => {
    //  console.log(datastock)
  }, [datastock])
  useLayoutEffect(() => {
    /* if (id) {
      getStocks()
    } */
    getinfusers()
    getinfcategories()
  }, [])
  const getqtt = iid => {
    try {
      return datastock.filter(o => o.categoryid.includes(iid))[0].qtt
    } catch (error) {
      //console.log(id)
      return 0
    }
  }
  const getsoldnumber = iid => {
    try {
      console.log(getoldqtt(iid))
      return datastock.filter(o => o.categoryid.includes(iid))[0].minqtt
    } catch (error) {
      //console.log(id)
      return 0
    }
  }
  const getoldqtt = iid => {
    try {
      return datastock.filter(o => o.categoryid.includes(iid))[0].oldqtt
    } catch (error) {
      //console.log(id)
      return 0
    }
  }
  const getnewqtt = iid => {
    try {
      return datastock.filter(o => o.categoryid.includes(iid))[0].newqtt
    } catch (error) {
      //console.log(id)
      return 0
    }
  }

  return (
    <div>
      <div className='row justify-content-md-center'>
        <div className='col-12 grid-margin stretch-card'>
          <div className='card'>
            <div className='card-body'>
              {id ? (
                <h3>Ajouter quantité pour chaque catégorie</h3>
              ) : (
                <h3>Ajouter un point de vente</h3>
              )}
              <Modal centered show={loading}>
                <Footballloader />
              </Modal>
              <br />
              <br />
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                {!id ? (
                  <>
                    <Container>
                      <Row className='justify-content-md-center'>
                        <Col xxl lg='3'></Col>
                        <Col xxl lg='3'></Col>

                        <Col xxl lg='3'></Col>
                        <Col xxl lg='3'>
                          <Form.Group className='xl-3'>
                            <Form.Check
                            size="xl"
                              name='isfree'
                              checked={statepdv.isfree}
                              onChange={event =>
                                setStatepdv({
                                  ...statepdv,
                                  isfree: !statepdv.isfree
                                })
                              }
                              label='Gratuit'
                              feedbackType='invalid'
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Container>
                    <br />
                    <br />
                    <Form.Group
                      as={Row}
                      className='mb-3 '
                      controlId='validationCustom01'
                    >
                      <Form.Label column sm='2'>
                        <h6>
                          Nom du responsable
                          <span className='formrequired'>*</span>
                        </h6>
                      </Form.Label>
                      <Col sm='8'>
                        <Form.Control
                          name='firstname'
                          value={stateusr.firstname}
                          onChange={event => changeCredsusr(event)}
                          required
                          type='text'
                          placeholder='Nom'
                        />
                        <Form.Control.Feedback type='invalid'>
                          Le nom du responsable est obligatoire.
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className='mb-3'
                      controlId='validationCustom01'
                    >
                      <Form.Label column sm='2'>
                        <h6>
                          Prénom du responsable
                          <span className='formrequired'>*</span>
                        </h6>
                      </Form.Label>
                      <Col sm='8'>
                        <Form.Control
                          name='lastname'
                          value={stateusr.lastname}
                          onChange={event => changeCredsusr(event)}
                          required
                          type='text'
                          placeholder='Prenom'
                        />
                        <Form.Control.Feedback type='invalid'>
                          Le Prénom du responsable est obligatoire.
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className='mb-3'
                      controlId='validationCustom01'
                    >
                      <Form.Label column sm='2'>
                        <h6>
                          E-mail du responsable
                          <span className='formrequired'>*</span>
                        </h6>
                      </Form.Label>
                      <Col sm='8'>
                        <InputGroup>
                          <Form.Control
                            name='email'
                            value={stateusr.email}
                            onChange={event => changeCredsusr(event)}
                            required
                            type='email'
                            placeholder='azerty@cvbg.vf'
                          />
                          <InputGroup.Text id='inputGroupPrepend'>
                            @
                          </InputGroup.Text>
                          <Form.Control.Feedback type='invalid'>
                            L'email du responsable est obligatoire(ou/et la
                            forma est incorrect).
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className='mb-3'
                      controlId='validationCustom01'
                    >
                      <Form.Label column sm='2'>
                        <h6>
                          Le numéro du téléphone du responsable
                          <span className='formrequired'>*</span>
                        </h6>
                      </Form.Label>
                      <Col sm='8'>
                        <Form.Control
                          name='telephone'
                          value={stateusr.telephone}
                          onChange={event => changeCredsusr(event)}
                          required
                          type='text'
                          placeholder='Numéro du téléphone'
                        />
                        <Form.Control.Feedback type='invalid'>
                          Le numéro du téléphone du responsable est obligatoire.
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className='mb-3'
                      controlId='validationCustom01'
                    >
                      <Form.Label column sm='2'>
                        <h6>
                          Adresse du responsable
                          <span className='formrequired'>*</span>
                        </h6>
                      </Form.Label>
                      <Col sm='8'>
                        <Form.Control
                          name='address'
                          value={stateusr.address}
                          onChange={event => changeCredsusr(event)}
                          required
                          type='text'
                          placeholder='Adresse'
                        />
                        <Form.Control.Feedback type='invalid'>
                          L'adresse du responsable est obligatoire.
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className='mb-3'
                      controlId='validationCustom01'
                    >
                      <Form.Label column sm='2'>
                        <h6>
                          Nom d'utilisateur
                          <span className='formrequired'>*</span>
                        </h6>
                      </Form.Label>
                      <Col sm='8'>
                        <Form.Control
                          name='username'
                          value={stateusr.username}
                          onChange={event => changeCredsusr(event)}
                          required
                          minlength='4'
                          type='text'
                          placeholder="Nom d'utilisateur"
                        />
                        <Form.Control.Feedback type='invalid'>
                          Le nom d'utilisateur est obligatoire, il doit contenir
                          au moins 4 caractères.
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className='mb-3'
                      controlId='validationCustom01'
                    >
                      <Form.Label column sm='2'>
                        <h6>
                          Le mot de passe<span className='formrequired'>*</span>
                        </h6>
                      </Form.Label>
                      <Col sm='8'>
                        <Form.Control
                          name='password'
                          value={stateusr.password}
                          onChange={event => changeCredsusr(event)}
                          required
                          type='text'
                          minlength='8'
                          placeholder='Mot de passe'
                        />
                        <Form.Control.Feedback type='invalid'>
                          Le mot de passe est obligatoire, il doit contenir au
                          moins 8 caractères
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>

                    <Form.Group
                      as={Row}
                      className='mb-3'
                      controlId='validationname'
                    >
                      <Form.Label column sm='2'>
                        <h6>
                          Nom du point de vente
                          <span className='formrequired'>*</span>
                        </h6>
                      </Form.Label>
                      <Col sm='8'>
                        <Form.Control
                          name='name'
                          value={statepdv.name}
                          onChange={event => changeCredspdv(event)}
                          required
                          type='text'
                          placeholder='Nom'
                        />
                        <Form.Control.Feedback type='invalid'>
                          Le nom du point de vente est obligatoire.
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className='mb-3'
                      controlId='validationtelephone'
                    >
                      <Form.Label column sm='2'>
                        <h6>Numéro de téléphone du point de vente</h6>
                      </Form.Label>
                      <Col sm='8'>
                        <Form.Control
                          name='telephone'
                          value={statepdv.telephone}
                          onChange={event => changeCredspdv(event)}
                          type='text'
                          placeholder='Numéro du téléphone'
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className='mb-3'
                      controlId='validationaddress'
                    >
                      <Form.Label column sm='2'>
                        <h6> Adresse du point de vente</h6>
                      </Form.Label>
                      <Col sm='8'>
                        <Form.Control
                          name='address'
                          value={statepdv.address}
                          onChange={event => changeCredspdv(event)}
                          type='text'
                          placeholder='Adresse'
                        />
                      </Col>
                    </Form.Group>
                  </>
                ) : null}
                <Container>
                  {datacategories.map(option => (
                    <Row className='justify-md-center'>
                      {option.map(element => (
                        <Col xxl lg='4' hidden={element.quantity <= 0 && !id}>
                          <Form.Floating className='mb-3'>
                            <label htmlFor={element.id}>{element.name}</label>
                            <Col sm='6'>
                              <Form.Control
                                key={element.id}
                                id={element.id}
                                name={element.id}
                                value={getnewqtt(element.id)}
                                onChange={async event => {
                                  await changedatastock(event, element.id)
                                  //console.log(element.quantity)
                                }}
                                required
                                type='number'
                                min={getsoldnumber(element.id)}
                                max={
                                  id
                                    ? element.quantity + getoldqtt(element.id)
                                    : element.quantity
                                }
                              />
                              {!validated && (
                                <small
                                  id='quantityHelp'
                                  class='form-text text-muted'
                                >
                                  {element.name} doit être compris entre{' '}
                                  {getsoldnumber(element.id)} et
                                  {id
                                    ? element.quantity + getoldqtt(element.id)
                                    : element.quantity}
                                </small>
                              )}
                              <Form.Control.Feedback type='invalid'>
                                Ce champ est obligatoire et doit être compris
                                entre {getsoldnumber(element.id)} et{' '}
                                {element.quantity}
                              </Form.Control.Feedback>
                            </Col>
                          </Form.Floating>
                        </Col>
                      ))}
                    </Row>
                  ))}
                </Container>
                <Container>
                  <Row className='justify-content-md-center'>
                    <Col xxl lg='3'></Col>
                    <Col xxl lg='3'></Col>

                    <Col xxl lg='3'></Col>
                    <Col xxl lg='3'>
                      <Button className='btn btn-primary-red' type='submit'>
                        ok
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Addpdv
