import React, { useState } from 'react'
import { useLayoutEffect, useEffect } from 'react'
import Table from 'react-bootstrap/Table'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Link } from 'react-router-dom'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import Switch from 'react-switch'
import Moment from 'react-moment'
import './../../assets/css/table.css'
import Modal from 'react-bootstrap/Modal'
import Footballloader from '../loader/footballloader'
import * as subscriberservice from '../../services/SubscriberService'
import * as userservice from '../../services/UserService'
import * as accessmanager from '../../services/AccessManager'
import { Box, makeStyles, TextField, Typography } from '@material-ui/core'
import Swal from 'sweetalert2'
import * as categoryservice from '../../services/CategoryService'
import { Pagination } from '@material-ui/lab'
import { Edit } from '@mui/icons-material'
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed'
import * as pdvservice from '../../services/PdvService'
import * as localservice from '../../services/LocalStorage'
const useStyles = makeStyles(theme => ({
  value: {
    fontWeight: 'bold',
    fontSize: '20px  !important',
    marginLeft: '12px  !important'
  },
  name: {
    fontSize: '18px  !important',
    color: ' rgb(107, 114, 128)'
  },

  categoryCard: {
    width: 'auto',
    minWidth: '150px',
    border: 'solid',
    padding: '10px',
    // padding: "4px",
    marginRight: '18px',
    borderBottomRightRadius: '25px',
    borderWidth: '1px',
    borderColor: ' #2196f3',
    textAlign: 'center'
  },
  flex: {
    display: 'flex'
  },
  ul: {
    '& .MuiPaginationItem-root': {
      '&.Mui-selected': {
        backgroundColor: '#e2101a',
        color: '#fff'
      }
    }
  }
}))

const Listsubscribers = () => {
  const [loading, setLoading] = useState()
  const [cin, setCin] = useState(undefined)
  const [phone, setPhone] = useState(undefined)
  const [serialnumber, setSerialnumber] = useState(undefined)
  const [totalItems, setTotalItems] = useState(0)
  const [turnover, setTurnover] = useState(0)
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(10)
  const [totalPages, setTotalPages] = useState(0)
  const [frole, setFrole] = useState('')
  const [fstatus, setFstatus] = useState('all')
  const [data, setData] = useState([])
  const [datapdvs, setDatapdvs] = useState([])
  const [fpdv, setFpdv] = useState('all')
  const [startdate, setStartdate] = useState(null)
  const [enddate, setEnddate] = useState(null)
  const [fcategory, setFcategory] = useState('all')
  const [datacategories, setDatacategories] = useState([])

  const [isfree, setIsfree] = useState(false)
  /* serialnumber */
  useEffect(() => {
    getinfcategories()
    if (accessmanager.isAdmin()) {
      getisfree()
    }
    initdata()
    getturnover()
    getisfree()
  }, [page, fstatus, fpdv, startdate, enddate, fcategory])

  useLayoutEffect(() => {
    if (accessmanager.isAdmin()) {
      getinfpdvs()
    }
  }, [])
  const getinfcategories = async () => {
    var res = await categoryservice.getallcategoriessmall()
    if (res) {
      setDatacategories(res)
    }
  }
  const getturnover = async () => {
    const res = await categoryservice.gettournover(fpdv, startdate, enddate)
    if (res && res !== undefined && res !== null) {
      // console.log(res.code)
      if (res.code === 0) {
        if (res.data && res.data !== null && res.data !== undefined) {
          setTurnover(res.data)
        } else {
          setTurnover(0)
        }
      } else {
        setTurnover(0)
      }
    }
  }
  const initdata = async () => {
    setLoading(true)

    const res = await subscriberservice.getallsubscriberpagination(
      page - 1,
      size,
      fstatus,
      phone,
      cin,
      fpdv,
      startdate,
      enddate,
      fcategory,
      serialnumber
    )
    if (res && res !== undefined && res !== null) {
      var data0 = []
      data0 = res.data.data.subscribers

      for (let i = 0; i < data0.length; i++) {
        if (accessmanager.isAdmin()) {
          //  console.log(accessmanager.isAdmin() === true)
          if (data0[i].createdBy !== 'server') {
            const res = await userservice.getotheruser(data0[i].createdBy)
            var nameusr = ''
            if (res !== undefined && res !== null) {
              nameusr = res.firstname + ' ' + res.lastname
            }
            data0[i].createdBy = nameusr
          }
        }
      }
      setLoading(false)
      setData(data0)
      //console.log(JSON.stringify(data))
      setTotalItems(res.data.data.totalItems)
      setTotalPages(res.data.data.totalPages)
    }
  }
  const updatecategory = event => {
    setFcategory(event.target.value)
  }
  const updatepage = async index => {
    setPage(index + 1)
  }

  const getinfpdvs = async () => {
    var res = await userservice.findallsmalldash(1, 'pdv')

    if (res) {
      setDatapdvs(res)
    }
  }
  const updatestatus = event => {
    //console.log(event.target.value)
    setFstatus(event.target.value)
  }

  const switchstatus = async (i, e, id, status) => {
    if (e === true && status === 0) {
      const res = await subscriberservice.changerstatus(1, id)
      if (res !== undefined) {
        initdata()
      }
    } else if (e === false && status === 1) {
      const res = await subscriberservice.changerstatus(0, id)
      if (res !== undefined) {
        initdata()
      }
    }
  }
  const updatepdv = event => {
    setFpdv(event.target.value)
  }
  const createTable = () => {
    let table = []
    for (let i = 0; i < data.length; i++) {
      table.push(
        <tr key={i} id={data[i].id}>
          <td>
            {data[i].firstname} {data[i].lastname}
          </td>
          <td> {data[i].address} </td>
          <td> {data[i].telephone} </td>
          <td> {data[i].cin} </td>
          <td> {data[i].card.serialnumber} </td>
          <td>
            <Switch
              onChange={e => switchstatus(i, e, data[i].id, data[i].enable)}
              checked={data[i].enable === 1 ? true : false}
            />
          </td>
          {accessmanager.isAdmin() && <td> {data[i].createdBy}</td>}
          <td>
            <Moment format='DD-MM-YYYY hh:mm:ss'>{data[i].createdAt}</Moment>
          </td>
          <td>
            <Link to={`/page/editsubscriber/${data[i].id}`}>
              <Edit className={classes.edit} />
            </Link>

            {accessmanager.isPdv() && (
              <Link to={`/page/updatesubscription/${data[i].id}`}>
                <DynamicFeedIcon className={classes.edit} />
              </Link>
            )}
          </td>
        </tr>
      )
    }
    return table
  }

  const exportData = async () => {
    setLoading(true)

    const res = await subscriberservice.downloaddd(
      fpdv,
      startdate,
      enddate,
      fstatus,
      fcategory
    )
    setLoading(false)
    // console.log(res)
    if (res === 0) {
      Swal.fire({
        title: 'success!',
        text: 'Le fichier a été téléchargé avec succès',
        icon: 'success'
      })
    } else if (res === -2) {
      Swal.fire({
        title: 'warning!',
        text: 'Pas de données',
        icon: 'warning'
      })
    } else {
      Swal.fire({
        title: 'Error!',
        text: 'Une erreur se produit',
        icon: 'error'
      })
    }
  }

  const exportDatatpe = async () => {
    setLoading(true)
    const res = await subscriberservice.downloaddatatpe()
    setLoading(false)

    if (res === 0) {
      Swal.fire({
        title: 'success!',
        text: 'Le fichier a été téléchargé avec succès',
        icon: 'success'
      })
    } else if (res === -2) {
      Swal.fire({
        title: 'warning!',
        text: 'Pas de données',
        icon: 'warning'
      })
    } else {
      Swal.fire({
        title: 'Error!',
        text: 'Une erreur se produit',
        icon: 'error'
      })
    }
  }
  const getisfree = async () => {
    if (accessmanager.isPdv()) {
      setFpdv()
      var res = await pdvservice.isfreebyusr(localservice.loadId())
     // console.log(res)
      if (res) {
        setIsfree(res.isfree)
      }
    } else {
      if (fpdv !== 'all') {
        var res = await pdvservice.isfreebyusr(fpdv)
        if (res) {
          setIsfree(res.isfree)
        }
      } else {
        setIsfree(false)
      }
    }
  }
  const classes = useStyles()
  return (
    <div>
      <Modal centered show={loading}>
        <Footballloader />
      </Modal>
      <div className='row justify-content-md-center'>
        <div className='col-12 grid-margin stretch-card'>
          <div className='card'>
            <div className='card-body'>
              <Row>
                <h3>Liste d'abonnés</h3>
                <Col xl lg='2'></Col>
                <Col xl lg='2'></Col>
                <Col xl lg='2'></Col>
                <Box className={classes.categoryCard}>
                  <Typography className={classes.name}>
                    Nombre total d'abonnés
                  </Typography>
                  <Typography className={classes.value}>
                    {totalItems}
                  </Typography>
                </Box>
                {(accessmanager.isAdmin() ||
                  (accessmanager.isPdv() && !isfree)) && (
                  <Box className={classes.categoryCard}>
                    <Typography className={classes.name}>
                      Chiffre d'affaires
                    </Typography>
                    <Typography className={classes.value}>
                      {turnover}
                    </Typography>
                  </Box>
                )}
              </Row>
              <Row>
                <Col xl lg='1'></Col>
                <Col xl lg='3'></Col>
                <Col xl lg='3'></Col>
              </Row>
              <div>
                <form className='form-sample'>
                  <div className='container'>
                    <div className='row mt-4'>
                      <div className='col-md-6'>
                        <FormControl fullWidth>
                          <InputLabel id='demo-simple-select-label'>
                            Catégories
                          </InputLabel>
                          <Select
                            labelId='demo-simple-select-label'
                            id='demo-simple-select'
                            label='Catégorie'
                            value={fcategory}
                            onChange={e => updatecategory(e)}
                          >
                            <MenuItem key='all' value='all'>
                              Tous
                            </MenuItem>
                            {datacategories.map(option => (
                              <MenuItem key={option.id} value={option.id}>
                                {option.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    <div className='row mt-4'>
                      <div className='col-md-6'>
                        <FormControl fullWidth>
                          <InputLabel id='demo-simple-select-label'>
                            Statut
                          </InputLabel>
                          <Select
                            labelId='demo-simple-select-label'
                            id='demo-simple-select'
                            label='Statut'
                            value={fstatus}
                            onChange={e => updatestatus(e)}
                          >
                            <MenuItem value='all'>{'Tous '}</MenuItem>
                            <MenuItem value='1'>{'Activé '}</MenuItem>
                            <MenuItem value='0'>{'Désactivé '}</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                      {accessmanager.isAdmin() && (
                        <div className='col-md-6'>
                          <FormControl fullWidth>
                            <InputLabel id='demo-simple-select-label'>
                              Points de vente
                            </InputLabel>
                            <Select
                              labelId='demo-simple-select-label'
                              id='demo-simple-select'
                              label='category'
                              value={fpdv}
                              onChange={e => updatepdv(e)}
                            >
                              <MenuItem key='all' value='all'>
                                Tous
                              </MenuItem>
                              {datapdvs.map(option => (
                                <MenuItem key={option.id} value={option.id}>
                                  {option.pdv.name} : {option.firstname}{' '}
                                  {option.lastname}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      )}
                    </div>
                    <div className='row mt-4'>
                      <div className='col-md-6'>
                        <FormControl fullWidth>
                          <TextField
                            className='input'
                            id='startdate'
                            label='Date de début'
                            type='date'
                            /* sx={{ width: 220 }} */
                            InputLabelProps={{
                              shrink: true
                            }}
                            value={startdate}
                            onChange={e => setStartdate(e.target.value)}
                          />
                        </FormControl>
                      </div>

                      <div className='col-md-6'>
                        <FormControl fullWidth>
                          <TextField
                            id='enddate'
                            label='Date de fin'
                            type='date'
                            /* sx={{ width: 220 }} */
                            InputLabelProps={{
                              shrink: true
                            }}
                            value={enddate}
                            onChange={e => setEnddate(e.target.value)}
                          />
                        </FormControl>
                      </div>
                    </div>
                    <div className='row mt-4'>
                      <div className='col-sm-4'>
                        <FormControl fullWidth>
                          <TextField
                            type='number'
                            InputProps={{
                              inputProps: {
                                max: 9999999999999,
                                min: 0
                              }
                            }}
                            variant='outlined'
                            labelId='demo-simple-select-label'
                            id='demo-simple-select-serialnumber'
                            label='Numéro de série'
                            value={serialnumber}
                            onChange={e => setSerialnumber(e.target.value)}
                          />
                        </FormControl>
                      </div>
                      <div className='col-sm-4'>
                        <FormControl fullWidth>
                          <TextField
                            type='number'
                            InputProps={{
                              inputProps: { min: 0 }
                            }}
                            variant='outlined'
                            labelId='demo-simple-select-label'
                            id='demo-simple-select-cin'
                            label='CIN'
                            value={cin}
                            onChange={e => setCin(e.target.value)}
                          />
                        </FormControl>
                      </div>
                      <div className='col-sm-3'>
                        <FormControl fullWidth>
                          <TextField
                            type='number'
                            InputProps={{
                              inputProps: {
                                min: 0
                              }
                            }}
                            variant='outlined'
                            labelId='demo-simple-select-label'
                            id='demo-simple-select-cin'
                            label='Téléphone'
                            value={phone}
                            onChange={e => setPhone(e.target.value)}
                          />
                        </FormControl>
                      </div>

                      <div className='col-sm-1'>
                        <button
                          onClick={initdata}
                          type='button'
                          className='btn btn-default border-0'
                        >
                          <i class='fa fa-search mr-2' />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>

                <div className='mt-4 table-responsive'>
                  {accessmanager.isPdv() && (
                    <Container>
                      <Row className='justify-content-md-center'>
                        <Col></Col>
                        <Col md='auto'></Col>
                        <Col xs lg='4'>
                          <Link to='/page/addsubscriber'>
                            <button
                              type='button'
                              className='btn btn-primary-red'
                            >
                              Ajouter un nouveau Abonné
                            </button>
                          </Link>
                        </Col>
                      </Row>
                    </Container>
                  )}

                  <br />
                  <div className='mt-4 table-responsive'>
                    {/* table-striped table-borderless table-hover  */}
                    <Table
                      responsive
                      className='table table-striped table-hover '
                    >
                      <thead>
                        <tr>
                          <th> Nom </th>
                          <th> Adresse </th>
                          <th> Numéro de téléphone </th>
                          <th> CIN </th>
                          <th> Carte </th>
                          <th> Activer </th>
                          {accessmanager.isAdmin() && <th> crée par </th>}
                          <th> crée à </th>
                          <th> Actions </th>
                        </tr>
                      </thead>
                      <tbody>{createTable()}</tbody>
                    </Table>
                  </div>
                  <br />
                  <br />
                  {accessmanager.isAdmin() && (
                    <Container>
                      <Row className='justify-content-md-center'>
                        <Col xxl lg='9'></Col>
                        <Col xxl lg='2'>
                          <button
                            onClick={exportData}
                            type='button'
                            className='btn btn-primary-red'
                          >
                            <i className='fa fa-download mr-2' />
                          </button>
                        </Col>
                        <Col xxl>
                          <button
                            onClick={exportDatatpe}
                            type='button'
                            className='btn btn-default'
                          >
                            <i class='fa fa-database mr-2' />
                          </button>
                        </Col>
                      </Row>
                    </Container>
                  )}
                  <br />
                  <Pagination
                    id='PaginationItem'
                    name='PaginationItem'
                    page={page}
                    count={totalPages}
                    shape='rounded'
                    classes={{ ul: classes.ul }}
                    boundaryCount={2}
                    showFirstButton
                    showLastButton
                    onChange={(e, newPage) => updatepage(newPage - 1)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Listsubscribers
