import React from 'react'
import s from './Footer.module.scss'

const Footer = () => {
  return (
    <div className={s.footer}>
      <span className={s.footerLabel}>
        2022 &copy;
        <a href='http://paypos.tn/fr' target='_blank' rel="noreferrer">
          PAYPOS
        </a>{' '}
      </span>
    </div>
  )
}

export default Footer
