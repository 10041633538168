import React, { useState } from "react";
import { useLayoutEffect, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import "./../../assets/css/table.css";
import Modal from "react-bootstrap/Modal";
import Footballloader from "../loader/footballloader";
import * as categoryservice from "../../services/CategoryService";
import * as userservice from "../../services/UserService";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  value: {
    fontWeight: "bold",
    fontSize: "20px  !important",
    marginLeft: "12px  !important",
  },
  name: {
    fontSize: "18px  !important",
    color: " rgb(107, 114, 128)",
  },

  categoryCard: {
    width: "auto",
    minWidth: "150px",
    border: "solid",
    padding: "10px",
    // padding: "4px",
    marginRight: "18px",
    borderBottomRightRadius: "25px",
    borderWidth: "1px",
    borderColor: " #2196f3",
    textAlign: "center",
  },
  flex: {
    display: "flex",
  },
  ul: {
    "& .MuiPaginationItem-root": {
      '&.Mui-selected': {
      backgroundColor: "#e2101a",
    color:'#fff'}
    }
  }
}));

const Listcategories = () => {
  const [loading, setLoading] = useState();
  const [active, setActive] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [data, setData] = useState([]);
  useEffect(() => {
    initdata();
  }, [page]);

  useLayoutEffect(() => {
    //console.log('useLayoutEffect Statements')
  }, []);
  const initdata = async () => {
    setLoading(true);
    const res = await categoryservice.getallcategorypagination(
      page - 1,
      size,
      null,
      null
    );
    //console.log(res)
    if (res !== undefined && res !== null) {
      var data0 = [];
      data0 = res.data.data.categories;
     /*  for (const element of data0) {
        if (element.createdBy !== "server") {
          const res = await userservice.getotheruser(element.createdBy);
          var nameusr = "";
          if (res !== undefined && res !== null) {
            nameusr = res.firstname + " " + res.lastname;
          }
          element.createdBy = nameusr;
        }
      } */
      setLoading(false);
      setData(data0);
      setTotalItems(res.data.data.totalItems);
      setTotalPages(res.data.data.totalPages);
    }
  };
  const getpagination = () => {
    let pagbt = [];
    if (page !== 1) {
      pagbt.push(
        <li className="page-item" key="previous" onClick={() => previous()}>
          <button
            className="btn btn-outline-primary-red"
            href="#"
            aria-label="Previous"
          >
            <span aria-hidden="true">&laquo;</span>
            <span className="sr-only">Previous</span>
          </button>
        </li>
      );
    } else {
      pagbt.push(
        <li className="page-item" key="previous">
          <button
            className="btn btn-outline-secondary"
            href="#"
            aria-label="Previous"
            disabled
          >
            <span aria-hidden="true">&laquo;</span>
            <span className="sr-only">Previous</span>
          </button>
        </li>
      );
    }
    for (let index = 0; index < totalPages; index++) {
      if (page === index + 1) {
        pagbt.push(
          <li
            className="page-item"
            key={index}
            onClick={() => updatepage(index)}
          >
            <button className="btn btn-primary-red">{index + 1} </button>
          </li>
        );
      } else {
        pagbt.push(
          <li
            className="page-item"
            key={index}
            onClick={() => updatepage(index)}
          >
            <button className="btn btn-outline-primary-red">
              {index + 1}{" "}
            </button>
          </li>
        );
      }
    }
    if (page !== totalPages) {
      pagbt.push(
        <li className="page-item" key="next" onClick={() => next()}>
          <button
            className="btn btn-outline-primary-red"
            href="#"
            aria-label="Next"
          >
            <span aria-hidden="true">&raquo;</span>
            <span className="sr-only">Next</span>
          </button>
        </li>
      );
    } else {
      pagbt.push(
        <li className="page-item" key="next" onClick={() => next()}>
          <button
            className="btn btn-outline-secondary"
            href="#"
            aria-label="Next"
            disabled
          >
            <span aria-hidden="true">&raquo;</span>
            <span className="sr-only">Next</span>
          </button>
        </li>
      );
    }
    return pagbt;
  };
  const updatepage = async (index) => {
    setPage(index + 1);
  };
  const previous = () => {
    if (page !== 1) {
      setPage(page - 1);
      initdata();
    }
  };
  const next = () => {
    if (page !== totalPages) {
      setPage(page + 1);
      initdata();
    }
  };

  const createTable = () => {
    let table = [];
    for (let i = 0; i < data.length; i++) {
      table.push(
        <tr key={i} id={data[i].id}>
          <td> {data[i].name} </td>
          <td> {data[i].quantity} </td>
          <td> {data[i].residue} </td>
          <td> {data[i].sold} </td>
          <td> {data[i].affected} </td>
          <td> {data[i].prix} </td>

         {/*  <td> {data[i].createdBy}</td> */}
          <td>
            <Moment format="DD-MM-YYYY hh:mm:ss">{data[i].createdAt}</Moment>
          </td>
        </tr>
      );
    }
    return table;
  };

  const classes = useStyles();
  return (
    <div>
      <Modal centered show={loading}>
        <Footballloader />
      </Modal>
      <div className="row justify-content-md-center">
        <div className="col-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <Row>
                <h3>Liste des catégories</h3>
                <Col xl lg="2"></Col>
                <Col xl lg="2"></Col>
                <Col xl lg="2"></Col>
                <Box className={classes.categoryCard}>
                  <Typography className={classes.name}>
                    Nombre total de catégories
                  </Typography>
                  <Typography className={classes.value}>
                    {totalItems}
                  </Typography>
                  {/* <i className='fa fa-download mr-2' /> */}
                </Box>
              </Row>
              <Row>
                <Col xl lg="1"></Col>
                <Col xl lg="3"></Col>
                <Col xl lg="3"></Col>
              </Row>
              <div className="mt-4 table-responsive">
                <Container>
                  <Row className="justify-content-md-center">
                    <Col xxl lg="4"></Col>
                    <Col xxl lg="4"></Col>
                    <Col xxl lg="4">
                      <Link to="/page/addcategory">
                        <button type="button" className="btn btn-primary-red">
                          Ajouter une nouvelle catégorie
                        </button>
                      </Link>
                    </Col>
                  </Row>
                </Container>

                <br />
                <div className="mt-4 table-responsive">
                  {/* table-striped table-borderless table-hover  */}
                  <Table
                    responsive
                    className="table table-striped table-hover "
                  >
                    <thead>
                      <tr>
                        <th> Nom </th>
                       <th> Quantité totale </th>
                        <th> Quantité restante </th>
                        <th> Quantité vendue </th>
                        <th> Quantité affectée </th>
                        <th> Prix </th>
{/* 
                        <th> Crée par </th> */}
                        <th> Crée à </th>
                      </tr>
                    </thead>
                    <tbody>{createTable()}</tbody>
                  </Table>
                </div>
                <br />
                <br />
                <br />
                <Pagination
                    id="PaginationItem"
                    name="PaginationItem"
                    page={page}
                   count={totalPages}
                    shape="rounded"
                   
                    classes={{ ul: classes.ul }}
                    boundaryCount={2}
                    showFirstButton
                    showLastButton
                    onChange={(e, newPage) => updatepage(newPage - 1)}
                  />
                {/* <nav aria-label="Page navigation example">
                  <ul className="pagination">{getpagination()}</ul>
                </nav> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Listcategories;
