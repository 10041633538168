import React from "react";
import {
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import s from "./ErrorPage.module.scss";

import errorImage from "../../assets/image/Mascotte_Etoile_du_sahel.png"
import FooterIcon from "../../components/Icons/FooterIcon.js";

const ErrorPage = () => {
  return (
    <div className={s.pageContainer}>
      <div className={s.errorContainer}>
        <h1 className={s.errorCode}>404</h1>
        <p className={s.errorInfo}>
        </p>
        <p className={s.errorHelp}>
        </p>
        <Link to="/page/dashboard">
          <Button className={`${s.errorBtn} rounded-pill`} type="submit" color="secondary-red">
            Back to Home
          </Button>
        </Link>
      </div>
      <div className={s.imageContainer}>
        <img className={s.errorImage} src={errorImage} alt="Error page" width="50" />
      </div>
   
    </div>
  );
}

export default ErrorPage;
