import React, { useState } from 'react'
import { useLayoutEffect, useEffect } from 'react'
import Table from 'react-bootstrap/Table'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Link } from 'react-router-dom'
import Moment from 'react-moment'
import { Badge } from 'reactstrap'
import Modal from 'react-bootstrap/Modal'
import { Box, makeStyles, Typography } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import { Edit, Visibility } from '@mui/icons-material'
import Footballloader from '../loader/footballloader'
import * as orderservice from '../../services/OrderServices'
import './../../assets/css/table.css'
import './listorders.css'
const useStyles = makeStyles(theme => ({
  value: {
    fontWeight: 'bold',
    fontSize: '20px  !important',
    marginLeft: '12px  !important'
  },
  name: {
    fontSize: '18px  !important',
    color: ' rgb(107, 114, 128)'
  },

  categoryCard: {
    width: 'auto',
    minWidth: '150px',
    border: 'solid',
    padding: '10px',
    // padding: "4px",
    marginRight: '18px',
    borderBottomRightRadius: '25px',
    borderWidth: '1px',
    borderColor: ' #2196f3',
    textAlign: 'center'
  },
  flex: {
    display: 'flex'
  },
  ul: {
    '& .MuiPaginationItem-root': {
      '&.Mui-selected': {
        backgroundColor: '#e2101a',
        color: '#fff'
      }
    }
  }
}))

const Listorders = () => {
  const [loading, setLoading] = useState()
  const [totalItems, setTotalItems] = useState(0)
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(10)
  const [totalPages, setTotalPages] = useState(0)
  const [data, setData] = useState([])
  const [startdate, setStartdate] = useState(null)
  const [enddate, setEnddate] = useState(null)
  const [fstatus, setFstatus] = useState('1')
  const [fstatusdelivery, setFstatusdelivery] = useState('all')

  useEffect(() => {
    initdata()
  }, [page, fstatus, startdate, enddate, fstatusdelivery])

  useLayoutEffect(() => {
    //console.log('useLayoutEffect Statements')
  }, [])
  const initdata = async () => {
    setLoading(true)
    const res = await orderservice.getallorderpagination(
      page - 1,
      size,
      fstatus,
      startdate,
      enddate,
      fstatusdelivery
    )
    //console.log(res)
    if (res !== undefined && res !== null) {
      var data0 = []
      // console.log(res)
      data0 = res?.data?.data?.rows
      setLoading(false)
      setData(data0)
      setTotalItems(res?.data?.data?.totalItems)
      setTotalPages(res?.data?.data?.totalPages)
    }
  }
  const updatepage = async index => {
    setPage(index + 1)
  }

  const createTable = () => {
    let table = []
    try {
      for (let i = 0; i < data.length; i++) {
        table.push(
          <tr key={i} id={data[i].id}>
            <td>{data[i].order_number}</td>
            <td> {data[i].fullName} </td>
            <td> {data[i].cin} </td>
            <td> {data[i].quantity}</td>
            <td> {data[i].amount} </td>
            <td> {data[i].email} </td>
            <td> {data[i].phone} </td>
            <td>
              <Badge
                size='large'
                /* En cours */
                className={
                  data[i].status === 1
                    ? 'badgeok'
                    : data[i].status === 2
                    ? 'badgenotok'
                    : 'badgenontont'
                }
              >
                {data[i].status === 1
                  ? 'Paiement réussi'
                  : data[i].status === 2
                  ? 'Paiement rejeté'
                  : 'Non effectué'}
              </Badge>
            </td>
            <td>
              <Moment format='DD-MM-YYYY hh:mm:ss'>{data[i].createdAt}</Moment>
            </td>
            <td>{getstatusDelivery(data[i].statusDelivery)} </td>
            <td>
              <Link to={`/page/showorder/${data[i].id}`}>
                <Visibility style={{ cursor: 'pointer' }} />
              </Link>
              {/*  <Link to={`/page/updateorder/${data[i].id}`}>
                <Edit className={classes.edit} />
              </Link> */}
            </td>
          </tr>
        )
      }
    } catch (error) {}
    return table
  }
  const getstatusDelivery = statusd => {
    switch (statusd) {
      case 0:
        return (
          <Badge
            size='large'
            style={{ color: '#aea8a8', backgroundColor: '#aea8a8' }}
          >
            <span style={{ color: '	#fff' }}>En attente</span>
          </Badge>
        )
        break
      case 2:
        return (
          <Badge
            size='large'
            style={{ color: '#ffa700', backgroundColor: '#ffa700' }}
          >
            <span style={{ color: '#fff' }}>En préparation</span>{' '}
          </Badge>
        )
        break
      case 3:
        return (
          <Badge
            size='large'
            style={{ color: '#43bc13', backgroundColor: '#43bc13' }}
          >
            <span style={{ color: '#fff' }}>Expédié</span>{' '}
          </Badge>
        )
        break
      case 4:
        return (
          <Badge
            size='large'
            style={{ color: '#00CEFF', backgroundColor: '#00CEFF' }}
          >
            <span style={{ color: '#fff' }}>Livré</span>
          </Badge>
        )
        break
      case 5:
        return (
          <Badge
            size='large'
            style={{ color: '#ff0000', backgroundColor: '#ff0000' }}
          >
            <span style={{ color: '#fff' }}>Annulé</span>
          </Badge>
        )
        break
      default:
        return (
          <Badge
            size='large'
            style={{ color: '#2e7d32', backgroundColor: '#2e7d32' }}
          >
            <span style={{ color: '#fff' }}>Indéfini</span>
          </Badge>
        )
        break
    }
  }
  const classes = useStyles()
  return (
    <div>
      <Modal centered show={loading}>
        <Footballloader />
      </Modal>
      <div className='row justify-content-md-center'>
        <div className='col-12 grid-margin stretch-card'>
          <div className='card'>
            <div className='card-body'>
              <Row>
                <h3>Liste des commandes</h3>
                <Col xl lg='2'></Col>
                <Col xl lg='2'></Col>
                <Col xl lg='2'></Col>
                <Box className={classes.categoryCard}>
                  <Typography className={classes.name}>
                    Nombre total de commandes
                  </Typography>
                  <Typography className={classes.value}>
                    {totalItems}
                  </Typography>
                  {/* <i className='fa fa-download mr-2' /> */}
                </Box>
              </Row>
              <Row>
                <Col xl lg='1'></Col>
                <Col xl lg='3'></Col>
                <Col xl lg='3'></Col>
              </Row>
              <div>
                <form className='form-sample'>
                  <div className='container'>
                    <div className='row mt-4'>
                      <div className='col-md-6'>
                        <FormControl fullWidth>
                          <InputLabel id='demo-simple-select-label1'>
                            Statut
                          </InputLabel>
                          <Select
                            labelId='demo-simple-select-label1'
                            id='demo-simple-select'
                            label='Statut'
                            value={fstatus}
                            onChange={event => setFstatus(event.target.value)}
                          >
                            {/*   1 'Paiement réussi'
                                  2 'Paiement rejeté'
                                  0 'Non effectué' */}
                            <MenuItem key='all' value='all'>
                              Tous
                            </MenuItem>
                            <MenuItem key='1' value='1'>
                              Paiement réussi
                            </MenuItem>
                            <MenuItem key='2' value='2'>
                              Paiement rejeté
                            </MenuItem>
                            <MenuItem key='0' value='0'>
                              Opération non effectuée
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                      <div className='col-md-6'>
                        <FormControl fullWidth>
                          <InputLabel id='demo-simple-select-label2'>
                            État de livraison
                          </InputLabel>
                          <Select
                            labelId='demo-simple-select-label2'
                            id='demo-simple-select'
                            label='État de livraison'
                            value={fstatusdelivery}
                            onChange={event =>
                              setFstatusdelivery(event.target.value)
                            }
                          >
                            {/*   1 'Paiement réussi'
                                  2 'Paiement rejeté'
                                  0 'Non effectué' */}
                            <MenuItem key='all' value='all'>
                              Tous
                            </MenuItem>
                            <MenuItem key='0' value='0'>
                              En attente
                            </MenuItem>
                            <MenuItem key='2' value='2'>
                              En préparation
                            </MenuItem>
                            <MenuItem key='3' value='3'>
                              Expédié
                            </MenuItem>
                            <MenuItem key='4' value='4'>
                              Livré
                            </MenuItem>
                            <MenuItem key='5' value='5'>
                              Annulé
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    <div className='row mt-4'>
                      <div className='col-md-6'>
                        <FormControl fullWidth>
                          <TextField
                            className='input'
                            id='startdate'
                            label='Date de début'
                            type='date'
                            /* sx={{ width: 220 }} */
                            InputLabelProps={{
                              shrink: true
                            }}
                            value={startdate}
                            onChange={e => setStartdate(e.target.value)}
                          />
                        </FormControl>
                      </div>

                      <div className='col-md-6'>
                        <FormControl fullWidth>
                          <TextField
                            className='input'
                            id='enddate'
                            label='Date de fin'
                            type='date'
                            /* sx={{ width: 220 }} */
                            InputLabelProps={{
                              shrink: true
                            }}
                            value={enddate}
                            onChange={e => setEnddate(e.target.value)}
                          />
                        </FormControl>
                      </div>
                    </div>
                  </div>
                </form>
                <div className='mt-4 table-responsive'>
                  <br />
                  <div className='mt-4 table-responsive'>
                    {/* table-striped table-borderless table-hover  */}
                    <Table
                      responsive
                      className='table table-striped table-hover '
                    >
                      <thead>
                        <tr>
                          <th> Numéro du commande </th>
                          <th> Nom complet </th>
                          <th> CIN</th>
                          <th> Quantité </th>
                          <th> Montant </th>
                          <th> E-mail </th>
                          <th> Telephone </th>
                          <th> Statut </th>
                          <th> Crée à </th>
                          <th> État de livraison</th>
                          <th> Actions </th>
                        </tr>
                      </thead>
                      <tbody>{createTable()}</tbody>
                    </Table>
                  </div>
                  <br />
                  <br />
                  <br />
                  <Pagination
                    id='PaginationItem'
                    name='PaginationItem'
                    page={page}
                    count={totalPages}
                    shape='rounded'
                    classes={{ ul: classes.ul }}
                    boundaryCount={2}
                    showFirstButton
                    showLastButton
                    onChange={(e, newPage) => updatepage(newPage - 1)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Listorders
