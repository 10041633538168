import React, { useState } from "react";
import { useLayoutEffect, useEffect } from "react";
import Swal from "sweetalert2";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Footballloader from "../loader/footballloader";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import * as cardservice from "../../services/CardServices";
import * as categoryservice from "../../services/CategoryService";
const Addcard = () => {
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [datacategories, setDatacategories] = useState([]);
  const [state, setState] = useState({
    idnfc: "",
    serialnumber: "",
    affected: "0",
    categoryid: "",
  });
  const changeCreds = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
    const form = event.currentTarget;
    if (form.checkValidity() === true) {
      setLoading(true);
      const resadd = await cardservice.addcard(state);
      if (resadd !== undefined && resadd !== null) {
        setLoading(false);
        if (resadd.data.code === 0) {
          Swal.fire({
            title: "success!",
            text: "Utilisateur ajouté avec succès",
            icon: "success",
            confirmButtonText: "OK",
          }).then((result) => {
            window.location.href = "#/page/cards";
          });
        } else {
          Swal.fire({
            title: "Error!",
            text: "une erreur(" + resadd.data.code + ") se produit avec ",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      }
    }
  };
  const getinfcategories = async () => {
    var res = await categoryservice.getallcategoriessmall();
    if (res) {
      setDatacategories(res);
    }
  };
  useEffect(() => {
    //console.log('useEffect Statements')
  });

  useLayoutEffect(() => {
    getinfcategories();
  }, []);
  return (
    <div>
      <div className="row justify-content-md-center">
        <div className="col-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h3>Ajouter une nouvelle carte</h3>
              <Modal centered show={loading}>
                <Footballloader />
              </Modal>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="validationCustom01"
                >
                  <Form.Label column sm="2">
                    Identifiant
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      name="idnfc"
                      value={state.idnfc}
                      onChange={(event) => changeCreds(event)}
                      required
                      type="text"
                      pattern="0x[0-9A-Fa-f]{8}"
                      placeholder="Identifiant"
                    />
                    <Form.Control.Feedback type="invalid">
                      L'Identifiant ne peut pas être vide et doit être au format
                      hexadécimal
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="validationCustom01"
                >
                  <Form.Label column sm="2">
                    Numéro de série
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      name="serialnumber"
                      value={state.serialnumber}
                      onChange={(event) => changeCreds(event)}
                      required
                      type="text"
                      pattern="[0-9]{10-13}"
                      placeholder=" Numéro de série"
                    />
                    <Form.Control.Feedback type="invalid">
                      Le numéro de série ne peut pas être vide et doit comporter
                      13 chiffres
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="validationCustom01"
                >
                  <Form.Label column sm="2">
                    Catégorie
                  </Form.Label>
                  <Col sm="8">
                    <Form.Select
                      id="categoryid"
                      className="form-control"
                      name="categoryid"
                      value={state.categoryid}
                      onChange={(event) => changeCreds(event)}
                      required
                    >
                      <option value="">Ouvrir</option>
                      {datacategories.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Veuillez choisir un category
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Container>
                  <Row className="justify-content-md-center">
                    <Col xxl lg="3"></Col>
                    <Col xxl lg="3"></Col>

                    <Col xxl lg="3"></Col>
                    <Col xxl lg="3">
                      <Button className="btn btn-primary-red" type="submit">
                        ok
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Addcard;
