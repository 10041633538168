import React, { useState } from 'react'
import { useLayoutEffect, useEffect } from 'react'
import Table from 'react-bootstrap/Table'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Link } from 'react-router-dom'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
//import { Checkmark } from "react-checkmark";
import Moment from 'react-moment'
import Form from 'react-bootstrap/Form'
import './../../assets/css/table.css'
import { Badge } from 'reactstrap'
import Switch from 'react-switch'
import Swal from 'sweetalert2'
import Modal from 'react-bootstrap/Modal'
import Footballloader from '../loader/footballloader'
import * as accessmanager from '../../services/AccessManager'
import * as cardservice from '../../services/CardServices'
import * as userservice from '../../services/UserService'
import * as categoryservice from '../../services/CategoryService'
//import * as pdvservice from "../../services/PdvService";
import Pagination from '@material-ui/lab/Pagination'
import './listcards.css'
import { Box, Typography, TextField } from '@mui/material'
import { makeStyles } from '@material-ui/core'
const useStyles = makeStyles(theme => ({
  value: {
    fontWeight: 'bold',
    fontSize: '20px  !important',
    marginLeft: '12px  !important'
  },
  name: {
    fontSize: '18px  !important',
    color: ' rgb(107, 114, 128)'
  },

  categoryCard: {
    width: 'auto',
    minWidth: '150px',
    border: 'solid',
    padding: '10px',
    // padding: "4px",
    marginRight: '18px',
    borderBottomRightRadius: '25px',
    borderWidth: '1px',
    borderColor: ' #2196f3',
    textAlign: 'center'
  },
  flex: {
    display: 'flex'
  },
  ul: {
    '& .MuiPaginationItem-root': {
      '&.Mui-selected': {
        backgroundColor: '#e2101a',
        color: '#fff'
      }
    }
  }
}))
const Listcards = () => {
  const [loading, setLoading] = useState()
  const [active, setActive] = useState(1)
  const [totalItems, setTotalItems] = useState(0)
  const [datacategories, setDatacategories] = useState([])
  const [datapdvs, setDatapdvs] = useState([])
  const [serialnumber, setSerialnumber] = useState(undefined)
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(10)
  const [totalPages, setTotalPages] = useState(0)
  const [faffected, setFaffected] = useState('all')
  const [fstatus, setFstatus] = useState('all')
  const [fcategory, setFcategory] = useState('all')
  const [fpdv, setFpdv] = useState('all')
  const [data, setData] = useState([])
  const [startdate, setStartdate] = useState(null)
  const [enddate, setEnddate] = useState(null)

  useEffect(() => {
    initdata()
  }, [page, faffected, fstatus, fcategory, fpdv, startdate, enddate])

  useLayoutEffect(() => {
    getinfcategories()
    if (accessmanager.isAdmin()) {
      getinfpdvs()
    }
  }, [])
  const getinfcategories = async () => {
    var res = await categoryservice.getallcategoriessmall()
    if (res) {
      setDatacategories(res)
    }
  }
  const getinfpdvs = async () => {
    var res = await userservice.findallsmalldash(1, 'pdv')

    if (res) {
      setDatapdvs(res)
    }
  }

  const initdata = async () => {
    setLoading(true)
    var res01 = await categoryservice.getallcategoriessmall()
    const res = await cardservice.getallcardpagination(
      page - 1,
      size,
      null,
      null,
      faffected,
      fstatus,
      fcategory,
      fpdv,
      startdate,
      enddate,
      serialnumber
    )
    // console.log(res)
    if (res !== undefined && res !== null) {
      var data0 = []
      data0 = res.data.data.cards

      for (let i = 0; i < data0.length; i++) {
        if (accessmanager.isAdmin()) {
          /* const res = await userservice.getotheruser(data0[i].createdBy)
          var nameusr = ''
          if (res !== undefined && res !== null) {
            nameusr = res.firstname + ' ' + res.lastname
          }
          data0[i].createdBy = nameusr */
          if (
            data0[i].affected === 1 &&
            data0[i].updatedBy &&
            data0[i].updatedBy !== null &&
            data0[i].updatedBy !== undefined
          ) {
            var nameusr02 = ''

            const res02 = await userservice.getotheruser(data0[i].updatedBy)
            if (res02 !== undefined && res !== null) {
              nameusr02 = res02.firstname + ' ' + res02.lastname
            }
            data0[i].updatedBy = nameusr02
          } else {
            data0[i].updatedBy = '  '
            data0[i].updatedAt = ''
          }
        }
      }

      setData(data0)
      // console.log(JSON.stringify(data))
      setTotalItems(res.data.data.totalItems)
      setTotalPages(res.data.data.totalPages)

      setLoading(false)
    }
  }
  const updatepage = async index => {
    setPage(index + 1)
  }

  const updateaffected = event => {
    //  console.log(event.target.value)
    setFaffected(event.target.value)
  }
  const updatestatus = event => {
    // console.log(event.target.value)
    setFstatus(event.target.value)
  }
  const updatecategory = event => {
    setFcategory(event.target.value)
  }
  const updatepdv = event => {
    setFpdv(event.target.value)
  }
  const convertBase64 = file => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result)
      }
      fileReader.onerror = error => {
        reject(error)
      }
    })
  }
  const switchstatus = async (i, e, id, status) => {
    if (e === true && status === 0) {
      const res = await cardservice.changerstatus(1, id)
      if (res !== undefined) {
        initdata()
      }
    } else if (e === false && status === 1) {
      const res = await cardservice.changerstatus(0, id)
      if (res !== undefined) {
        initdata()
      }
    }
  }
  const changeCreds = async event => {
    setLoading(true)
    const file = event.target.files[0]
    const base64 = await convertBase64(file)
    if (base64 && base64 !== null && base64 !== undefined) {
      const res = await cardservice.importfile(base64)
      if (res !== undefined && res !== null) {
        if (res.data.code === 0) {
          /* "success":9,"failed":1224 */
          var icon = ''
          if (res.data.data.success === 0) {
            icon = 'error'
          } else if (res.data.data.failed === 0) {
            icon = 'success'
          } else if (res.data.data.failed < res.data.data.success) {
            icon = 'info'
          } else {
            icon = 'warning'
          }
          setLoading(false)
          Swal.fire({
            title: 'Import de fichier!',
            text:
              res.data.data.success +
              '  lignes ont été ajoutées parmis  ' +
              (res.data.data.success + res.data.data.failed) +
              '   lignes',
            icon: icon,
            confirmButtonText: 'OK'
          }).then(result => {
            window.location.reload()
          })
        } else {
          Swal.fire({
            title: 'Error!',
            text: 'une erreur(' + res.data.code + ') se produit avec ',
            icon: 'error',
            confirmButtonText: 'OK'
          })
        }
      }
    }
  }
  const createTable = () => {
    let table = []
    for (let i = 0; i < data.length; i++) {
      table.push(
        <tr key={i} id={data[i].id}>
          <td> {data[i].idnfc} </td>
          <td> {data[i].serialnumber} </td>
          <td> {data[i].category.name} </td>
          {accessmanager.isAdmin() && (
            <td>
              <Switch
                onChange={e => switchstatus(i, e, data[i].id, data[i].status)}
                checked={data[i].status === 1 ? true : false}
              />
            </td>
          )}
          {/*   {accessmanager.isAdmin() && <td> {data[i].createdBy}</td>} */}

          <td>
            <Moment format='DD-MM-YYYY hh:mm:ss'>{data[i].createdAt}</Moment>
          </td>

          {accessmanager.isAdmin() && <td> {data[i].updatedBy}</td>}
          {accessmanager.isAdmin() && (
            <td>
              {data[i].affected === 1 ? (
                <Moment format='DD-MM-YYYY hh:mm:ss'>
                  {data[i].updatedAt}
                </Moment>
              ) : (
                ' '
              )}
            </td>
          )}
          <td>
            <Badge
              size='large'
              className={data[i].affected === 0 ? 'badgenotok' : 'badgeok'}
              /* className='badgeok' */
            >
              {data[i].affected === 0 ? 'Non' : 'Oui'}
            </Badge>
          </td>
        </tr>
      )
    }
    return table
  }
  const exportData = async () => {
    setLoading(true)
    const res = await cardservice.downloaddd(
      faffected,
      fstatus,
      fcategory,
      fpdv,
      startdate,
      enddate
    )
    setLoading(false)
    // console.log(res)
    if (res === 0) {
      Swal.fire({
        title: 'success!',
        text: 'Le fichier a été téléchargé avec succès',
        icon: 'success'
      })
    } else if (res === -2) {
      Swal.fire({
        title: 'warning!',
        text: 'Pas de données',
        icon: 'warning'
      })
    } else {
      Swal.fire({
        title: 'Error!',
        text: 'Une erreur se produit',
        icon: 'error'
      })
    }
  }
  const classes = useStyles()
  return (
    <div>
      <Modal centered show={loading}>
        <Footballloader />
      </Modal>
      <div className='row justify-content-md-center'>
        <div className='col-12 grid-margin stretch-card'>
          <div className='card'>
            <div className='card-body'>
              <Row>
                <h3>Liste des cartes </h3>
                <Col xl lg='2'></Col>
                <Col xl lg='2'></Col>
                <Col xl lg='2'></Col>
                <Box className={classes.categoryCard}>
                  <Typography className={classes.name}>
                    Nombre total de cartes
                  </Typography>
                  <Typography className={classes.value}>
                    {totalItems}
                  </Typography>
                  {/* <i className='fa fa-download mr-2' /> */}
                </Box>
              </Row>
              <Row>
                <Col xl lg='1'></Col>
                <Col xl lg='3'></Col>
                <Col xl lg='3'></Col>
              </Row>
              <div>
                <form className='form-sample'>
                  <div className='container'>
                    <div className='row mt-4'>
                      <div className='col-md-6'>
                        <FormControl fullWidth>
                          <InputLabel id='demo-simple-select-label'>
                            Catégories
                          </InputLabel>
                          <Select
                            labelId='demo-simple-select-label'
                            id='demo-simple-select'
                            label='Catégorie'
                            value={fcategory}
                            onChange={e => updatecategory(e)}
                          >
                            <MenuItem key='all' value='all'>
                              Tous
                            </MenuItem>
                            {datacategories.map(option => (
                              <MenuItem key={option.id} value={option.id}>
                                {option.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div className='col-md-6'>
                        <FormControl fullWidth>
                          <InputLabel id='demo-simple-select-label'>
                            Statut
                          </InputLabel>
                          <Select
                            labelId='demo-simple-select-label'
                            id='demo-simple-select'
                            label='Statut'
                            value={fstatus}
                            onChange={e => updatestatus(e)}
                          >
                            <MenuItem value='all'>{'Tous '}</MenuItem>
                            <MenuItem value='1'>{'activée '}</MenuItem>
                            <MenuItem value='0'>{'bloquée'}</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    <div className='row mt-4'>
                      {accessmanager.isAdmin() && (
                        <div className='col-md-6'>
                          <FormControl fullWidth>
                            <InputLabel id='demo-simple-select-label'>
                              Affectées
                            </InputLabel>
                            <Select
                              labelId='demo-simple-select-label'
                              id='demo-simple-select'
                              label='Affectée'
                              value={faffected}
                              onChange={e => updateaffected(e)}
                            >
                              <MenuItem value='all'>{'Tous '}</MenuItem>
                              <MenuItem value='1'>{'Oui '}</MenuItem>
                              <MenuItem value='0'>{'Non '}</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                      )}

                      {accessmanager.isAdmin() && (
                        <div className='col-md-6'>
                          <FormControl fullWidth>
                            <InputLabel id='demo-simple-select-label'>
                              Points de vente
                            </InputLabel>
                            <Select
                              labelId='demo-simple-select-label'
                              id='demo-simple-select'
                              label='category'
                              value={fpdv}
                              onChange={e => updatepdv(e)}
                            >
                              <MenuItem key='all' value='all'>
                                Tous
                              </MenuItem>
                              {datapdvs.map(option => (
                                <MenuItem key={option.id} value={option.id}>
                                  {option.pdv.name} : {option.firstname}{' '}
                                  {option.lastname}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      )}
                    </div>
                    <div className='row mt-4'>
                      <div className='col-md-6'>
                        <FormControl fullWidth>
                          <TextField
                            className='input'
                            id='startdate'
                            label='Date de début'
                            type='date'
                            /* sx={{ width: 220 }} */
                            InputLabelProps={{
                              shrink: true
                            }}
                            value={startdate}
                            onChange={e => setStartdate(e.target.value)}
                          />
                        </FormControl>
                      </div>

                      <div className='col-md-6'>
                        <FormControl fullWidth>
                          <TextField
                            id='enddate'
                            label='Date de fin'
                            type='date'
                            /* sx={{ width: 220 }} */
                            InputLabelProps={{
                              shrink: true
                            }}
                            value={enddate}
                            onChange={e => setEnddate(e.target.value)}
                          />
                        </FormControl>
                      </div>
                    </div>
                    <div className='row mt-4'>
                    <div className='col-md-6'>
                        <FormControl fullWidth>
                          <TextField
                            type='number'
                            InputProps={{
                              inputProps: {
                                min: 0
                              }
                            }}
                            variant='outlined'
                            labelId='demo-simple-select-label'
                            id='demo-simple-select-serialnumber'
                            label='Numéro de série'
                            value={serialnumber}
                            onChange={e => setSerialnumber(e.target.value)}
                          />
                        </FormControl>
                      </div>
                      <div className='col-sm-1'>
                        <button
                          onClick={initdata}
                          type='button'
                          className='btn btn-outline-default border-0'
                        >
                          <i class='fa fa-search mr-2' />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>

                <div className='mt-4 table-responsive'>
                  {accessmanager.isAdmin() && (
                    <Container>
                      <Row className='justify-content-md-center'>
                        <Col xxl lg='4'>
                          <Form>
                            <Col sm='8'>
                              <label
                                className='btn btn-primary-red'
                                for='upload'
                              >
                                Importer un fichier
                              </label>
                              <input
                                id='upload'
                                onChange={event => changeCreds(event)}
                                hidden
                                accept='.csv'
                                type='file'
                              />
                            </Col>
                          </Form>
                        </Col>
                        <Col xxl lg='4'></Col>
                        <Col xxl lg='4'>
                          <Link to='/page/addcard'>
                            <button
                              type='button'
                              className='btn btn-primary-red'
                            >
                              Ajouter une nouvelle carte
                            </button>
                          </Link>
                        </Col>
                      </Row>
                    </Container>
                  )}

                  <br />
                  <div className='mt-4 table-responsive'>
                    {/* table-striped table-borderless table-hover  */}
                    <Table
                      responsive
                      className='table table-striped table-hover '
                    >
                      <thead>
                        <tr>
                          <th> Identifiant NFC </th>
                          <th> Numéro de série </th>
                          <th> Catégorie </th>
                          {accessmanager.isAdmin() && <th> Statut </th>}
                          {/*  {accessmanager.isAdmin() && <th> Créée par </th>} */}
                          {accessmanager.isAdmin() && <th> Créée le </th>}
                          {accessmanager.isAdmin() && <th>Vendue par </th>}
                          <th>Vendue le </th>
                          <th>Affectée </th>
                        </tr>
                      </thead>
                      <tbody>{createTable()}</tbody>
                    </Table>
                  </div>
                  <br />
                  <br />
                  {accessmanager.isAdmin() && (
                    <Container>
                      <Row className='justify-content-md-center'>
                        <Col xxl lg='5'></Col>
                        <Col xxl lg='5'></Col>
                        <Col xxl>
                          <button
                            onClick={exportData}
                            type='button'
                            className='btn btn-primary-red'
                          >
                            <i className='fa fa-download mr-2' />
                          </button>
                        </Col>
                      </Row>
                    </Container>
                  )}
                  <br />
                  <Pagination
                    id='PaginationItem'
                    name='PaginationItem'
                    page={page}
                    count={totalPages}
                    shape='rounded'
                    classes={{ ul: classes.ul }}
                    boundaryCount={2}
                    showFirstButton
                    showLastButton
                    onChange={(e, newPage) => updatepage(newPage - 1)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Listcards
