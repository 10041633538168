export const CODE_STATUS = {
  CARD_NOT_SOLD: '0',
  BLOCKED_CARD: '1',
  SUCCESS_CARD: '2'
}
export const CODE_ACCESS = {
  INITIAL: '0',
  FAILED: '1',
  SUCCESS: '2'
}
