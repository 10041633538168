import React, { useState } from 'react'
import { useLayoutEffect, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'

import {
  Navbar,
  Nav,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap'

import { logoutUser } from '../../actions/auth'
import { closeSidebar, openSidebar } from '../../actions/navigation'
import MenuIcon from '../Icons/HeaderIcons/MenuIcon'
import ProfileIcon from '../../assets/navbarMenus/pfofileIcons/ProfileIcon'
import logoutIcon from '../../assets/navbarMenus/pfofileIcons/logoutOutlined.svg'
import editprofile from '../../assets/navbarMenus/pfofileIcons/editprofile.svg'
import * as loginservice from '../../services/LoginService'
import teamImg from '../../assets/image/tshirt.png'
import * as localstorage from '../../services/LocalStorage'
import s from './Header.module.scss'
import 'animate.css'

const Header = props => {
  const [notificationsOpen, setNotificationsOpen] = useState(false)
  useLayoutEffect(() => {}, [])
  useEffect(() => {}, [])
  const toggleNotifications = () => {
    setNotificationsOpen(!notificationsOpen)
  }

  const toggleSidebar = () => {
    if (props.sidebarOpened) {
      props.dispatch(closeSidebar())
    } else {
      const paths = props.location.pathname.split('/')
      paths.pop()
      props.dispatch(openSidebar())
    }
  }

  const doLogout = async () => {
    const log = await loginservice.logoutt()
    props.dispatch(logoutUser())
    window.location.href = '#/login'
  }

  const getusrname = () => {
    if (
      localstorage.loadName() !== undefined &&
      localstorage.loadName() !== null
    ) {
      return localstorage.loadName()
    } else {
      return '----------'
    }
  }

  return (
    <Navbar className={`${s.root} d-print-none`}>
      <div>
        <NavLink
          onClick={() => toggleSidebar()}
          className={`d-md-none mr-3 ${s.navItem}`}
          href='#'
        >
          <MenuIcon className={s.menuIcon} />
        </NavLink>
      </div>
      <Nav className='ml-auto'>
        <NavItem className='d-sm-none mr-4'>
          <NavLink className='' href='#'>
            {/*  <SearchIcon /> */}
          </NavLink>
        </NavItem>

        <Dropdown
          isOpen={notificationsOpen}
          toggle={() => toggleNotifications()}
          nav
          id='basic-nav-dropdown'
          className='ml-3'
        >
          <DropdownToggle nav caret className='navbar-dropdown-toggle'>
            <span className={`${s.avatar} rounded-circle float-left `}>
              <img src={teamImg} alt='etoile_du_sahel' />
            </span>
            <span className='small d-none d-sm-block ml-1 mr-2 body-1'>
              {getusrname()}
            </span>
          </DropdownToggle>
          <DropdownMenu
            className='navbar-dropdown profile-dropdown'
            style={{ width: '230px' }}
          >
            <a href='#/page/profile/user'>
              <DropdownItem className={s.dropdownProfileItem}>
                <ProfileIcon />
                <span>Profil</span>
              </DropdownItem>
            </a>
            <a href='#/page/profile/edit'>
              <DropdownItem className={s.dropdownProfileItem}>
                <img
                  src={editprofile}
                  alt='Basket Icon'
                  style={{ height: 60, width: 25 }}
                />
                <span>Modifier le profil</span>
              </DropdownItem>
            </a>
            <NavItem>
              <NavLink onClick={() => doLogout()} href='#'>
                <button
                  className='btn btn-primary-red rounded-pill mx-auto logout-btn'
                  type='submit'
                >
                  <img src={logoutIcon} alt='Logout' />
                  <span className='ml-1'>Déconnexion</span>
                </button>
              </NavLink>
            </NavItem>
          </DropdownMenu>
        </Dropdown>
      </Nav>
    </Navbar>
  )
}

Header.propTypes = {
  dispatch: PropTypes.func.isRequired,
  sidebarOpened: PropTypes.bool
}

function mapStateToProps (store) {
  return {
    sidebarOpened: store.navigation.sidebarOpened,
    sidebarStatic: store.navigation.sidebarStatic
  }
}

export default withRouter(connect(mapStateToProps)(Header))
