import React, { useState } from 'react'
import { useLayoutEffect, useEffect } from 'react'
import Table from 'react-bootstrap/Table'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Badge } from 'reactstrap'
import { Link } from 'react-router-dom'
import Moment from 'react-moment'
import Modal from 'react-bootstrap/Modal'
import { Box, makeStyles, TextField, Typography } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import { useParams } from 'react-router'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import Footballloader from '../loader/footballloader'
import * as accesseventservice from '../../services/AccessEventService'
import * as userservice from '../../services/UserService'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import './../../assets/css/table.css'
import './listaccessevents.css'
import { CODE_STATUS, CODE_ACCESS } from './status.config'
const useStyles = makeStyles(theme => ({
  value: {
    fontWeight: 'bold',
    fontSize: '20px  !important',
    marginLeft: '12px  !important'
  },
  name: {
    fontSize: '18px  !important',
    color: ' rgb(107, 114, 128)'
  },

  categoryCard: {
    width: 'auto',
    minWidth: '150px',
    border: 'solid',
    padding: '10px',
    // padding: "4px",
    marginRight: '18px',
    borderBottomRightRadius: '25px',
    borderWidth: '1px',
    borderColor: ' #2196f3',
    textAlign: 'center'
  },
  flex: {
    display: 'flex'
  },
  ul: {
    '& .MuiPaginationItem-root': {
      '&.Mui-selected': {
        backgroundColor: '#e2101a',
        color: '#fff'
      }
    }
  }
}))

const Listaccessdata = () => {
  const [loading, setLoading] = useState()
  const [page, setPage] = useState(1)
  const [totalItems, setTotalItems] = useState(0)
  const [size, setSize] = useState(10)
  const [totalPages, setTotalPages] = useState(0)
  const [cin, setCin] = useState(undefined)
  const [phone, setPhone] = useState(undefined)
  const [serialnumber, setSerialnumber] = useState(undefined)
  const [data, setData] = useState([])
  const { accesseventid } = useParams()
  const [faccess, setFsaccess] = useState('all')
  useEffect(() => {
    initdata()
  }, [page, faccess])

  useLayoutEffect(() => {
    //console.log('useLayoutEffect Statements')
  }, [])
  const initdata = async () => {
    setLoading(true)
    console.log(accesseventid)
    const res = await accesseventservice.getallaccessdatapagination(
      page - 1,
      size,
      accesseventid,
      cin,
      phone,
      serialnumber,
      faccess
    )
    //console.log(res)
    if (res !== undefined && res !== null) {
      var data0 = []
      data0 = res.data.data.accessdata

      setLoading(false)
      setData(data0)
      setTotalItems(res.data.data.totalItems)
      setTotalPages(res.data.data.totalPages)
    }
  }

  const updatepage = async index => {
    setPage(index + 1)
  }
  const getcolempty = () => {
    return (
      <span style={{ color: `#9f9f9f`, fontWeight: `bold` }}>Indéfini</span>
    )
  }
  const getaccess = statusd => {
    switch (statusd) {
      case CODE_ACCESS.INITIAL:
        return (
          <Badge
            size='large'
            style={{ color: '#aea8a8', backgroundColor: '#aea8a8' }}
          >
            <span style={{ color: '	#fff' }}>En attente</span>
          </Badge>
        )
        break
      case CODE_ACCESS.SUCCESS:
        return (
          <Badge
            size='large'
            style={{ color: '#43bc13', backgroundColor: '#43bc13' }}
          >
            <span style={{ color: '#fff' }}>Accès réussi</span>{' '}
          </Badge>
        )
        break
      case CODE_ACCESS.FAILED:
        return (
          <Badge
            size='large'
            style={{ color: '#ff0000', backgroundColor: '#ff0000' }}
          >
            <span style={{ color: '#fff' }}>Accès échoué</span>
          </Badge>
        )
        break
      default:
        return (
          <Badge
            size='large'
            style={{ color: '#2e7d32', backgroundColor: '#2e7d32' }}
          >
            <span style={{ color: '#fff' }}>Indéfini</span>
          </Badge>
        )
        break
    }
  }
  const createTable = () => {
    let table = []
    for (let i = 0; i < data.length; i++) {
      table.push(
        <tr key={i} id={data[i].id}>
          <td>{data[i].idnfc ? data[i].idnfc : getcolempty()}</td>
          <td>{data[i].serialnumber ? data[i].serialnumber : getcolempty()}</td>
          <td>{data[i].fullname ? data[i].fullname : getcolempty()}</td>
          <td> {data[i].cin ? data[i].cin : getcolempty()} </td>
          <td> {data[i].phone ? data[i].phone : getcolempty()} </td>
          <td>
            <Badge
              size='large'
              /* En cours */
              className={
                data[i].status === CODE_STATUS.SUCCESS_CARD
                  ? 'badgeok'
                  : data[i].status === CODE_STATUS.BLOCKED_CARD
                  ? 'badgenotok'
                  : 'badgenontont'
              }
            >
              {data[i].status === CODE_STATUS.SUCCESS_CARD
                ? 'Carte réussie'
                : data[i].status === CODE_STATUS.BLOCKED_CARD
                ? 'Carte bloquée'
                : 'Carte non vendue'}
            </Badge>
          </td>
          <td>{getaccess(data[i].access)}</td>
          <td>
            {data[i].accessevent
              ? data[i].accessevent.code
                ? data[i].accessevent.code
                : getcolempty()
              : getcolempty()}
          </td>
          <td>{data[i].createdAt ? data[i].createdAt : getcolempty()}</td>
        </tr>
      )
    }
    return table
  }

  const classes = useStyles()
  return (
    <div>
      <Modal centered show={loading}>
        <Footballloader />
      </Modal>
      <div className='row justify-content-md-center'>
        <div className='col-12 grid-margin stretch-card'>
          <div className='card'>
            <div className='card-body'>
              <Row>
                <h3>Liste des matchs</h3>
                <Col xl lg='2'></Col>
                <Col xl lg='2'></Col>
                <Col xl lg='2'></Col>
                <Box className={classes.categoryCard}>
                  <Typography className={classes.name}>
                    Nombre total des cartes
                  </Typography>
                  <Typography className={classes.value}>
                    {totalItems}
                  </Typography>
                  {/* <i className='fa fa-download mr-2' /> */}
                </Box>
              </Row>
              <Row>
                <Col xl lg='1'></Col>
                <Col xl lg='3'></Col>
                <Col xl lg='3'></Col>
              </Row>
              <div>
                <form className='form-sample'>
                  <div className='container'>
                    <div className='row mt-4'>
                      <div className='col-md-6'>
                        <FormControl fullWidth>
                          <InputLabel id='demo-simple-select-label1'>
                            Statut
                          </InputLabel>
                          <Select
                            labelId='demo-simple-select-label1'
                            id='demo-simple-select'
                            label='Statut'
                            value={faccess}
                            onChange={event => setFsaccess(event.target.value)}
                          >
                            {/*   1 'Paiement réussi'
                                  2 'Paiement rejeté'
                                  0 'Non effectué' */}
                            <MenuItem key='all' value='all'>
                              Tous
                            </MenuItem>
                            <MenuItem
                              key={CODE_ACCESS.SUCCESS}
                              value={CODE_ACCESS.SUCCESS}
                            >
                              Accès réussi
                            </MenuItem>
                            <MenuItem
                              key={CODE_ACCESS.FAILED}
                              value={CODE_ACCESS.FAILED}
                            >
                              Accès échoué
                            </MenuItem>
                            <MenuItem
                              key={CODE_ACCESS.INITIAL}
                              value={CODE_ACCESS.INITIAL}
                            >
                              En attente
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    <div className='row mt-4'>
                      <div className='col-sm-4'>
                        <FormControl fullWidth>
                          <TextField
                            type='number'
                            InputProps={{
                              inputProps: {
                                max: 9999999999999,
                                min: 0
                              }
                            }}
                            variant='outlined'
                            labelId='demo-simple-select-label'
                            id='demo-simple-select-serialnumber'
                            label='Numéro de série'
                            value={serialnumber}
                            onChange={e => setSerialnumber(e.target.value)}
                          />
                        </FormControl>
                      </div>
                      <div className='col-sm-4'>
                        <FormControl fullWidth>
                          <TextField
                            type='number'
                            InputProps={{
                              inputProps: { min: 0 }
                            }}
                            variant='outlined'
                            labelId='demo-simple-select-label'
                            id='demo-simple-select-cin'
                            label='CIN'
                            value={cin}
                            onChange={e => setCin(e.target.value)}
                          />
                        </FormControl>
                      </div>
                      <div className='col-sm-3'>
                        <FormControl fullWidth>
                          <TextField
                            type='number'
                            InputProps={{
                              inputProps: {
                                min: 0
                              }
                            }}
                            variant='outlined'
                            labelId='demo-simple-select-label'
                            id='demo-simple-select-cin'
                            label='Téléphone'
                            value={phone}
                            onChange={e => setPhone(e.target.value)}
                          />
                        </FormControl>
                      </div>

                      <div className='col-sm-1'>
                        <button
                          onClick={initdata}
                          type='button'
                          className='btn btn-default border-0'
                        >
                          <i class='fa fa-search mr-2' />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
                <div className='mt-4 table-responsive'>
                  <div className='mt-4 table-responsive'>
                    {/* table-striped table-borderless table-hover  */}
                    <Table
                      responsive
                      className='table table-striped table-hover '
                    >
                      <thead>
                        <tr>
                          <th> Identifiant NFC </th>
                          <th> Numéro de série </th>
                          <th> Nom complet </th>
                          <th> CIN </th>
                          <th> N° téléphone </th>
                          <th> Statut </th>
                          <th>Accès </th>
                          <th> Match </th>
                          <th> Créé par </th>
                        </tr>
                      </thead>
                      <tbody>{createTable()}</tbody>
                    </Table>
                  </div>
                  <br />
                  <br />
                  <br />
                  <Pagination
                    id='PaginationItem'
                    name='PaginationItem'
                    page={page}
                    count={totalPages}
                    shape='rounded'
                    classes={{ ul: classes.ul }}
                    boundaryCount={2}
                    showFirstButton
                    showLastButton
                    onChange={(e, newPage) => updatepage(newPage - 1)}
                  />
                  {/* <nav aria-label="Page navigation example">
                  <ul className="pagination">{getpagination()}</ul>
                </nav> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Listaccessdata
