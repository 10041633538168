import axios from './axios'
import Swal from 'sweetalert2'
import * as accessmanager from './AccessManager'
import * as localstorage from './LocalStorage'
import download from 'js-file-download'
//{{url}}/api/subscriber/get?page=0&size=1
export async function getallsubscriberpagination (
  page,
  size,
  enable,
  phone,
  cin,
  pdv,
  startdate,
  enddate,
  categoryid,
  serialnumber
) {
  if (
    localstorage.loadAccess() !== undefined &&
    localstorage.loadAccess() !== null
  ) {
    var filtre = ''
    if (enable && enable !== 'all') {
      filtre += '&enable=' + enable
    }
    if (accessmanager.isAdmin() && pdv && pdv !== 'all') {
      filtre += '&createdby=' + pdv
    }
    if (accessmanager.isPdv()) {
      filtre += '&createdby=' + localstorage.loadId()
    }
    if (phone) {
      filtre += '&telephone=' + phone
    }
    if (cin) {
      filtre += '&cin=' + cin
    }
    if (startdate && startdate !== 'all') {
      filtre += '&startdate=' + startdate
    }
    if (enddate && enddate !== 'all') {
      filtre += '&enddate=' + enddate
    }
    if (categoryid && categoryid !== 'all') {
      filtre += '&categoryid=' + categoryid
    }
    if (serialnumber && serialnumber !== 'all') {
      filtre += '&serialnumber=' + serialnumber
    }

    let res = await axios
      .get(`/subscriber/get?page=` + page + `&size=` + size + filtre)
      .catch(err => {
        return undefined
      })

    return res
  }
}
//{{url}}/api/subscriber/findall
export async function findallsubscriber () {
  if (
    localstorage.loadAccess() !== undefined &&
    localstorage.loadAccess() !== null
  ) {
    let res = await axios.get(`/subscriber/findall`).catch(err => {
      return undefined
    })
    return res
  }
}

export async function addsubscriber (state) {
  var enable = state.enable === true ? 1 : 0
  var subscriber =
    '{      "firstname": "' +
    state.firstname +
    '",      "lastname": "' +
    state.lastname +
    '",      "telephone": "' +
    state.telephone +
    '",      "address": "' +
    state.address
  if (state.email && state.email !== null && state.email !== undefined) {
    subscriber += '",      "email": "' + state.email
  }
  subscriber +=
    '",      "cin": "' +
    state.cin +
    '",      "enable":' +
    enable +
    ',      "cardid": "' +
    state.cardid +
    '" }'
  //console.log(subscriber)
  const instance = axios.create({
    timeout: 100000,
    headers: { Authorization: 'Bearer ' + localstorage.loadAccess() }
  })
  let res = await instance
    .post(`/subscriber/add/`, JSON.parse(subscriber))
    .catch(err => {
      Swal.fire({
        title: 'Error!',
        text: err.message,
        icon: 'error',
        confirmButtonText: 'OK'
      })
      return undefined
    })
  return res
}
export async function changerstatus (status, id) {
  if (
    localstorage.loadAccess() !== undefined &&
    localstorage.loadAccess() !== null
  ) {
    var body = '{     "enable": "' + status + '"  }'
    let res = await axios
      .patch(`/subscriber/updatestatus/` + id, JSON.parse(body))
      .catch(err => {
        return undefined
      })
    return res
  }
}

export async function downloaddd (pdv, startdate, enddate, enable, categoryid) {
  try {
    if (
      localstorage.loadAccess() !== undefined &&
      localstorage.loadAccess() !== null
    ) {
      var filtre = ''
      if (enable && enable !== 'all') {
        filtre += filtre ? '&' : '?'
        filtre += 'enable=' + enable
      }
      if (accessmanager.isAdmin() && pdv && pdv !== 'all') {
        filtre += filtre ? '&' : '?'
        filtre += 'createdby=' + pdv
      }
      if (accessmanager.isPdv()) {
        filtre += 'createdby=' + localstorage.loadId()
      }
      if (startdate && startdate !== 'all') {
        filtre += filtre ? '&' : '?'
        filtre += 'startdate=' + startdate
      }
      if (enddate && enddate !== 'all') {
        filtre += filtre ? '&' : '?'
        filtre += 'enddate=' + enddate
      }
      if (categoryid && categoryid !== 'all') {
        filtre += filtre ? '&' : '?'
        filtre += 'categoryid=' + categoryid
      }

      let res = await axios.get(`/subscriber/download` + filtre).catch(err => {
        return undefined
      })
      if (!res.data.code) {
        download(res.data, 'List_abonnes.csv')
        return 0
      } else {
        return -2
      }
    }
  } catch (error) {}
  return -1
}

export async function getsubscriber (id) {
  if (
    localstorage.loadAccess() !== undefined &&
    localstorage.loadAccess() !== null
  ) {
    let res = await axios.get(`/subscriber/get/` + id).catch(err => {
      return undefined
    })
    return res?.data
  }
}

export async function updatesubscriber (id, var_subscriber) {
  if (
    localstorage.loadAccess() !== undefined &&
    localstorage.loadAccess() !== null
  ) {
    let res = await axios
      .put(`/subscriber/update/` + id, var_subscriber)
      .catch(err => {
        return undefined
      })
    return res?.data
  }
}
export async function downloaddatatpe () {
  try {
    if (
      localstorage.loadAccess() !== undefined &&
      localstorage.loadAccess() !== null
    ) {
      let res = await axios.get(`/file/cardsaccessfile`).catch(err => {
        return undefined
      })
      if (!res.data.code) {
        const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
          JSON.stringify(res.data)
        )}`
        const link = document.createElement('a')
        link.href = jsonString
        link.download = 'accessdata.json'

        link.click()
        return 0
      } else {
        return -2
      }
    }
  } catch (error) {}
  return -1
}

export async function updatesubscription (var_subscriber) {
  if (
    localstorage.loadAccess() !== undefined &&
    localstorage.loadAccess() !== null
  ) {
    let res = await axios
      .post(`subscriber/updarecard` ,var_subscriber)
      .catch(err => {
        return undefined
      })
    return res?.data
  }
}
