import React, { useState } from 'react'
import { useLayoutEffect, useEffect } from 'react'
import Swal from 'sweetalert2'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { makeStyles, TextField } from '@material-ui/core'
import Autocomplete from '@mui/material/Autocomplete'
import Modal from 'react-bootstrap/Modal'
import Footballloader from '../loader/footballloader'
import * as subscriberservice from '../../services/SubscriberService'
import * as cardservice from '../../services/CardServices'
import * as categoryservice from '../../services/CategoryService'
const useStyles = makeStyles(() => ({
  dropdown: {
    '&.MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot': {
      padding: '0px !important',
      fontSize: '17px',
      color: '#000',
      fontWeight: 500
    },
    '& .MuiAutocomplete-endAdornment': {
      right: '0px !important'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#ced4da'
    }
  }
}))
const Addsubscriber = () => {
  const [loading, setLoading] = useState(false)
  const classes = useStyles()
  const [validated, setValidated] = useState(false)
  const [datacategories, setDatacategories] = useState([])
  const [datacards, setDatacards] = useState([])
  const [fcategory, setFcategory] = useState('all')
  const [state, setState] = useState({
    firstname: '',
    lastname: '',
    telephone: null,
    email: '',
    address: '',
    cin: '',
    enable: true,
    categoryid: '',
    cardid: ''
  })
  const changeCreds = event => {
    setState({ ...state, [event.target.name]: event.target.value })
  }

  const handleSubmit = async event => {
    event.preventDefault()
    event.stopPropagation()

    setValidated(true)
    const form = event.currentTarget
    if (form.checkValidity() === true) {
      setLoading(true)
      const resadd = await subscriberservice.addsubscriber(state)
      if (resadd !== undefined && resadd !== null) {
        setLoading(false)
        if (resadd.data.code === 0) {
          Swal.fire({
            title: 'success!',
            text: 'Abonné ajouté avec succès',
            icon: 'success',
            confirmButtonText: 'OK'
          }).then(result => {
            window.location.href = '#/page/subscribers'
          })
        } else {
          var msg = 'Transaction échouée  [ code ' + resadd.data.code + ']'
          if (resadd.data.code === -5 || resadd.data.code === -6) {
            msg = 'stock insuffisant'
          }
          Swal.fire({
            title: 'Error!',
            text: msg,
            icon: 'error',
            confirmButtonText: 'OK'
          })
        }
      }
    }
  }
  const getinfcards = async () => {
    var res = await cardservice.getallcardssmall(0, 1, fcategory)
    if (res) {
      setDatacards(res)
    }
  }
  const getinfcategories = async () => {
    var res = await categoryservice.getallcategoriessmall()
    if (res) {
      setDatacategories(res)
    }
  }
  useEffect(() => {
    getinfcards()
  }, [fcategory])

  useLayoutEffect(() => {
    getinfcards()
    getinfcategories()
  }, [])
  const onTagsChange = (event, values) => {
    //console.log('---------------------' + JSON.stringify(values))
    if (values && values !== null && values !== undefined) {
      setState({ ...state, cardid: values.id })
    } else {
      setState({ ...state, cardid: '' })
    }
  }
  return (
    <div>
      <div className='row justify-content-md-center'>
        <div className='col-12 grid-margin stretch-card'>
          <div className='card'>
            <div className='card-body'>
              <h3>Ajouter un nouveau Abonné</h3>
              <Modal centered show={loading}>
                <Footballloader />
              </Modal>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Group className='mb-3'>
                  <Form.Check
                    name='enable'
                    checked={state.enable}
                    onChange={event =>
                      setState({ ...state, enable: !state.enable })
                    }
                    required
                    label='Activer'
                    feedbackType='invalid'
                  />
                </Form.Group>
                <Form.Group
                  as={Row}
                  className='mb-3'
                  controlId='validationCustom01'
                >
                  <Form.Label column sm='2'>
                    Nom
                  </Form.Label>
                  <Col sm='8'>
                    <Form.Control
                      name='firstname'
                      value={state.firstname}
                      onChange={event => changeCreds(event)}
                      required
                      type='text'
                      placeholder='Nom'
                    />
                    <Form.Control.Feedback type='invalid'>
                      Le nom est obligatoire.
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  className='mb-3'
                  controlId='validationCustom01'
                >
                  <Form.Label column sm='2'>
                    Prenom
                  </Form.Label>
                  <Col sm='8'>
                    <Form.Control
                      name='lastname'
                      value={state.lastname}
                      onChange={event => changeCreds(event)}
                      required
                      type='text'
                      placeholder='Prenom'
                    />
                    <Form.Control.Feedback type='invalid'>
                      Le Prenom est obligatoire.
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group
                  as={Row}
                  className='mb-3'
                  controlId='validationCustom01'
                >
                  <Form.Label column sm='2'>
                    Le numéro du téléphone
                  </Form.Label>
                  <Col sm='8'>
                    <Form.Control
                      name='telephone'
                      value={state.telephone}
                      onChange={event => changeCreds(event)}
                      required
                      type='text'
                      placeholder='Numéro du téléphone'
                    />
                    <Form.Control.Feedback type='invalid'>
                      Le numéro du téléphone est obligatoire.
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  className='mb-3'
                  controlId='validationCustom01'
                >
                  <Form.Label column sm='2'>
                    Adresse
                  </Form.Label>
                  <Col sm='8'>
                    <Form.Control
                      name='address'
                      value={state.address}
                      onChange={event => changeCreds(event)}
                      required
                      type='text'
                      placeholder='Adresse'
                    />
                    <Form.Control.Feedback type='invalid'>
                      L'adresse est obligatoire.
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  className='mb-3'
                  controlId='validationCustom01'
                >
                  <Form.Label column sm='2'>
                    Numéro CIN
                  </Form.Label>
                  <Col sm='8'>
                    <Form.Control
                      name='cin'
                      value={state.cin}
                      onChange={event => changeCreds(event)}
                      required
                      type='text'
                      placeholder="Numéro carte d'identité nationale"
                    />
                    <Form.Control.Feedback type='invalid'>
                      Le numéro CIN est obligatoire.
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  className='mb-3'
                  controlId='validationCustom01'
                >
                  <Form.Label column sm='2'>
                    Catégorie
                  </Form.Label>
                  <Col sm='8'>
                    <Form.Select
                      id='categoryid'
                      className='form-control'
                      name='categoryid'
                      value={fcategory}
                      onChange={event => setFcategory(event.target.value)}
                    >
                      <option value=''>Ouvrir</option>
                      {datacategories.map(option => (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  className='mb-3'
                  controlId='validationCustom01'
                >
                  <Form.Label column sm='2'>
                    Card
                  </Form.Label>
                  <Col sm='8'>
                    <Form.Control
                      name='cardid'
                      required
                      value={state.cardid}
                      className='form-control'
                      type='text'
                      hidden
                    />

                    <Autocomplete
                      className={classes.dropdown}
                      onChange={onTagsChange}
                      options={datacards}
                      getOptionLabel={option => option.serialnumber}
                      renderInput={params => (
                        <TextField
                          placeholder='Numéro de série'
                          error={
                            validated &&
                            (!state.cardid ||
                              state.cardid === null ||
                              state.cardid === undefined)
                          }
                          {...params}
                          variant='outlined'
                        />
                      )}
                      size='small'
                    />
                    <Form.Control.Feedback type='invalid'>
                      Veuillez choisir un carte
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Container>
                  <Row className='justify-content-md-center'>
                    <Col xxl lg='3'></Col>
                    <Col xxl lg='3'></Col>

                    <Col xxl lg='3'></Col>
                    <Col xxl lg='3'>
                      <Button className='btn btn-primary-red' type='submit'>
                        ok
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Addsubscriber
