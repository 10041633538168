import React, { useState } from 'react'
import { useLayoutEffect, useEffect } from 'react'
import Table from 'react-bootstrap/Table'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Link } from 'react-router-dom'
import Moment from 'react-moment'
import Modal from 'react-bootstrap/Modal'
import { Box, makeStyles, TextField, Typography } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import FormControl from '@mui/material/FormControl'
import Form from 'react-bootstrap/Form'
import InputLabel from '@mui/material/InputLabel'
import Swal from 'sweetalert2'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import Footballloader from '../loader/footballloader'
import IconButton from '@mui/material/IconButton'
import * as accesseventservice from '../../services/AccessEventService'
import * as userservice from '../../services/UserService'
import './../../assets/css/table.css'

const useStyles = makeStyles(theme => ({
  value: {
    fontWeight: 'bold',
    fontSize: '20px  !important',
    marginLeft: '12px  !important'
  },
  name: {
    fontSize: '18px  !important',
    color: ' rgb(107, 114, 128)'
  },

  categoryCard: {
    width: 'auto',
    minWidth: '150px',
    border: 'solid',
    padding: '10px',
    // padding: "4px",
    marginRight: '18px',
    borderBottomRightRadius: '25px',
    borderWidth: '1px',
    borderColor: ' #2196f3',
    textAlign: 'center'
  },
  flex: {
    display: 'flex'
  },
  ul: {
    '& .MuiPaginationItem-root': {
      '&.Mui-selected': {
        backgroundColor: '#e2101a',
        color: '#fff'
      }
    }
  }
}))

const Listevents = () => {
  const [loading, setLoading] = useState()
  const [totalItems, setTotalItems] = useState(0)
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(10)
  const [totalPages, setTotalPages] = useState(0)

  const [startdate, setStartdate] = useState(null)
  const [enddate, setEnddate] = useState(null)
  const [code, setCode] = useState(undefined)
  const [name, setName] = useState(undefined)
  const [data, setData] = useState([])

  useEffect(() => {
    initdata()
  }, [page, startdate, enddate])

  useLayoutEffect(() => {
    //console.log('useLayoutEffect Statements')
  }, [])
  const initdata = async () => {
    setLoading(true)
    const res = await accesseventservice.getallaccesseventpagination(
      page - 1,
      size,
      startdate,
      enddate,
      code,
      name
    )
    //console.log(res)
    if (res !== undefined && res !== null) {
      var data0 = []
      data0 = res.data.data.events
      setLoading(false)
      setData(data0)
      setTotalItems(res.data.data.totalItems)
      setTotalPages(res.data.data.totalPages)
    }
  }

  const updatepage = async index => {
    setPage(index + 1)
  }
  const getcolempty = () => {
    return (
      <span style={{ color: `#9f9f9f`, fontWeight: `bold` }}>Indéfini</span>
    )
  }
  const exportData = async (accesseventid) => {
    setLoading(true)

    const res = await accesseventservice.downloaddd(accesseventid) 
    setLoading(false)
    // console.log(res)
    if (res === 0) {
      Swal.fire({
        title: 'success!',
        text: 'Le fichier a été téléchargé avec succès',
        icon: 'success'
      })
    } else if (res === -2) {
      Swal.fire({
        title: 'warning!',
        text: 'Pas de données',
        icon: 'warning'
      })
    } else {
      Swal.fire({
        title: 'Error!',
        text: 'Une erreur se produit',
        icon: 'error'
      })
    }
  }
  const createTable = () => {
    let table = []
    for (let i = 0; i < data.length; i++) {
      table.push(
        <tr key={i} id={data[i].id}>
          <td>{data[i].dateevent ? data[i].dateevent : getcolempty()}</td>
          <td>{data[i].code ? data[i].code : getcolempty()}</td>
          <td>{data[i].from ? data[i].from.toUpperCase() : getcolempty()}</td>
          <td> {data[i].imei ? data[i].imei : getcolempty()} </td>
          <td>
            <Link to={`/page/events/data/${data[i].id}`} >
              <FormatListBulletedIcon color="success" />
            </Link>
            <IconButton  onClick={() =>exportData(data[i].id)} >
              <FileDownloadIcon color="secondary" />
            </IconButton>
          </td>
        </tr>
      )
    }
    return table
  }
  const convertBase64 = file => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result)
      }
      fileReader.onerror = error => {
        reject(error)
      }
    })
  }
  const changeCreds = async event => {
    setLoading(true)
    const file = event.target.files[0]
    const base64 = await convertBase64(file)
    console.log(base64)
    if (base64 && base64 !== null && base64 !== undefined) {
      const res = await accesseventservice.importfile(base64)
      if (res !== undefined && res !== null) {
        if (res.data.code === 0) {
          /* "success":9,"failed":1224 */
          var icon = ''
          if (res.data.data.success === 0) {
            icon = 'error'
          } else if (res.data.data.failed === 0) {
            icon = 'success'
          } else if (res.data.data.failed < res.data.data.success) {
            icon = 'info'
          } else {
            icon = 'warning'
          }
          setLoading(false)
          Swal.fire({
            title: 'Import de fichier!',
            text:
              res.data.data.success +
              '  lignes ont été ajoutées parmis  ' +
              (res.data.data.success + res.data.data.failed) +
              '   lignes',
            icon: icon,
            confirmButtonText: 'OK'
          }).then(result => {
            window.location.reload()
          })
        } else {
          Swal.fire({
            title: 'Error!',
            text: 'une erreur(' + res.data.code + ') se produit avec ',
            icon: 'error',
            confirmButtonText: 'OK'
          })
        }
      }
    }
  }
  const classes = useStyles()
  return (
    <div>
      <Modal centered show={loading}>
        <Footballloader />
      </Modal>
      <div className='row justify-content-md-center'>
        <div className='col-12 grid-margin stretch-card'>
          <div className='card'>
            <div className='card-body'>
              <Row>
                <h3>Liste des matchs</h3>
                <Col xl lg='2'></Col>
                <Col xl lg='2'></Col>
                <Col xl lg='2'></Col>
                <Box className={classes.categoryCard}>
                  <Typography className={classes.name}>
                    Nombre total des matchs
                  </Typography>
                  <Typography className={classes.value}>
                    {totalItems}
                  </Typography>
                  {/* <i className='fa fa-download mr-2' /> */}
                </Box>
              </Row>
              <Row>
                <Col xl lg='1'></Col>
                <Col xl lg='3'></Col>
                <Col xl lg='3'></Col>
              </Row>
              <div>
                <form className='form-sample'>
                  <div className='container'>
                    <div className='row mt-4'>
                      <div className='col-md-6'>
                        <FormControl fullWidth>
                          <TextField
                            className='input'
                            id='startdate'
                            label='Date de début'
                            type='date'
                            /* sx={{ width: 220 }} */
                            InputLabelProps={{
                              shrink: true
                            }}
                            value={startdate}
                            onChange={e => setStartdate(e.target.value)}
                          />
                        </FormControl>
                      </div>

                      <div className='col-md-6'>
                        <FormControl fullWidth>
                          <TextField
                            id='enddate'
                            label='Date de fin'
                            type='date'
                            /* sx={{ width: 220 }} */
                            InputLabelProps={{
                              shrink: true
                            }}
                            value={enddate}
                            onChange={e => setEnddate(e.target.value)}
                          />
                        </FormControl>
                      </div>
                    </div>
                    <div className='row mt-5'>
                      <div className='col-sm-1'></div>
                      <div className='col-md-4'>
                        <FormControl fullWidth>
                          <TextField
                            variant='outlined'
                            labelId='demo-simple-select-label'
                            id='demo-simple-select-serialnumber'
                            label='Code'
                            value={code}
                            onChange={e => setCode(e.target.value)}
                          />
                        </FormControl>
                      </div>
                      <div className='col-md-4'>
                        <FormControl fullWidth>
                          <TextField
                            variant='outlined'
                            labelId='demo-simple-select-label'
                            id='demo-simple-select-cin'
                            label='Nome'
                            value={name}
                            onChange={e => setName(e.target.value)}
                          />
                        </FormControl>
                      </div>

                      <div className='col-sm-1'>
                        <button
                          onClick={initdata}
                          type='button'
                          className='btn btn-default border-0'
                        >
                          <i class='fa fa-search mr-2' />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
                <div className='mt-4 table-responsive'>
                  <Container>
                    <Row className='justify-content-md-center'>
                      <Col xxl lg='4'></Col>
                      <Col xxl lg='4'></Col>
                      <Col xxl lg='4'>
                        <Form>
                          <Col sm='8'>
                            <label className='btn btn-primary-red' for='upload'>
                              Importer un fichier
                            </label>
                            <input
                              id='upload'
                              onChange={event => changeCreds(event)}
                              hidden
                              accept='.json'
                              type='file'
                            />
                          </Col>
                        </Form>
                      </Col>
                    </Row>
                  </Container>

                  <br />
                  <div className='mt-4 table-responsive'>
                    {/* table-striped table-borderless table-hover  */}
                    <Table
                      responsive
                      className='table table-striped table-hover '
                    >
                      <thead>
                        <tr>
                          <th> Date </th>
                          <th> Code </th>
                          <th> Source </th>
                          <th> Référence</th>
                          <th> Actions </th>
                        </tr>
                      </thead>
                      <tbody>{createTable()}</tbody>
                    </Table>
                  </div>
                  <br />
                  <br />
                  <br />
                  <Pagination
                    id='PaginationItem'
                    name='PaginationItem'
                    page={page}
                    count={totalPages}
                    shape='rounded'
                    classes={{ ul: classes.ul }}
                    boundaryCount={2}
                    showFirstButton
                    showLastButton
                    onChange={(e, newPage) => updatepage(newPage - 1)}
                  />
                  {/* <nav aria-label="Page navigation example">
                  <ul className="pagination">{getpagination()}</ul>
                </nav> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Listevents
