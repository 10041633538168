import React, { useState } from 'react'
import Swal from 'sweetalert2'
import { useLayoutEffect, useEffect } from 'react'
import { ProgressBar, Dropdown } from 'react-bootstrap'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import ChartPie from './components/ChartPie'
import { Doughnut } from 'react-chartjs-2'
import * as userservice from '../../services/UserService'
import './dashboard.css'
import * as accessmanager from '../../services/AccessManager'
import * as dashboardservice from '../../services/DashboardService'
import * as categoryservice from '../../services/CategoryService'
import * as pdvservice from '../../services/PdvService'
import * as localservice from '../../services/LocalStorage'

const Dashboard = () => {
  const [totalquantity, setTotalquantity] = useState(0)
  const [residuequantity, setResiduequantity] = useState(0)
  const [reservedquantitypdv, setReservedquantitypdv] = useState(0)
  const [turnoversold, setTurnoversold] = useState(0)
  const [residuetotalsold, setResiduetotalsold] = useState(0)
  const [totalsold, setTotalsold] = useState(0)
  const [reservedquantityfree, setReservedquantityfree] = useState(0)
  const [cardsforfree, setCardsforfree] = useState(0)
  const [residuecardsforfree, setResiduecardsforfree] = useState(0)
  const [costfree, setCostfree] = useState(0)
  const [isfree, setIsfree] = useState(false)
  const [datacategories, setDatacategories] = useState([])
  const [fcategory, setFcategory] = useState('all')
  const [fcategoryname, setFcategoryname] = useState('Tous')
  const [datapdvs, setDatapdvs] = useState([])
  const [fpdv, setFpdv] = useState('all')
  const [fpdvname, setFpdvname] = useState('Tous')
  /* turnover */
  const getinf = async () => {
    if (accessmanager.isPdv()) {
      setFpdv(localservice.loadId())
    }
    setFcategoryname('Tous')
    for (var i = 0; i < datacategories.length; i++) {
      if (datacategories[i].id === fcategory) {
        setFcategoryname(datacategories[i].name)
        break
      }
    }
    if (accessmanager.isAdmin()) {
      setFpdvname('Tous')
      for (var c = 0; c < datapdvs.length; c++) {
        if (datapdvs[c].id === fpdv) {
          setFpdvname(
            datapdvs[c].pdv.name +
              ' : ' +
              datapdvs[c].firstname +
              ' ' +
              datapdvs[c].lastname
          )
          break
        }
      }
    }

    const res = await dashboardservice.getstatistics(fcategory, fpdv)
    //console.log(res)

    setReservedquantitypdv(res.reservedquantitypdv)
    setTurnoversold(res.turnoversold)
    setResiduetotalsold(res.residuetotalsold)
    setTotalsold(res.totalsold)
    setReservedquantityfree(res.reservedquantityfree)
    setCardsforfree(res.cardsforfree)
    setResiduecardsforfree(res.residuecardsforfree)
    setCostfree(res.costfree)
    if (accessmanager.isAdmin()) {
      setTotalquantity(res.totalquantity)
      setResiduequantity(res.residuequantity)
    }
  }
  const datatest = {
    labels: ['Cartes chez les points de vente', 'Cartes restantes'],
    datasets: [
      {
        label: '# of Votes',
        data: [totalquantity - residuequantity, residuequantity],
        backgroundColor: ['rgba(255, 99, 132, 0.2)', 'rgba(54, 162, 235, 0.2)'],
        borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)'],
        borderWidth: 1
      }
    ]
  }

  useEffect(() => {
    getinf()
  }, [fcategory, fpdv])

  useLayoutEffect(() => {
    getisfree()
    getinfcategories()
    if (accessmanager.isAdmin()) {
      getinfpdvs()
    }
  }, [])
  const getinfcategories = async () => {
    var res = await categoryservice.getallcategoriessmall()
    if (res) {
      setDatacategories(res)
    }
  }
  const getinfpdvs = async () => {
    var res = await userservice.findallsmalldash(1, 'pdv')

    if (res) {
      setDatapdvs(res)
    }
  }
  const getisfree = async () => {
    if (accessmanager.isPdv()) {
      setFpdv()
      var res = await pdvservice.isfreebyusr(localservice.loadId())
      //console.log(res)
      if (res) {
        setIsfree(res.isfree)
      }
    }
  }

  return (
    <div>
      <div className='row'>
        <div className='col-md-3'>
          <p className='body-2'>Catégories</p>
          <div className='p-2 '>
            <Dropdown onSelect={e => setFcategory(e)}>
              <Dropdown.Toggle
                variant='btn btn-secondary dropdown-toggle'
                id='idcategory'
              >
                {fcategoryname}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item eventKey='all'>Tous</Dropdown.Item>
                {datacategories.map(option => (
                  <Dropdown.Item eventKey={option.id}>
                    {option.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className='p-2'></div>
          <div className='p-2'></div>
        </div>
        {accessmanager.isAdmin() && (
          <div className='col-md-3'>
            <p className='body-2'>Points de vente </p>
            <div className='p-2'>
              <Dropdown onSelect={e => setFpdv(e)}>
                <Dropdown.Toggle
                  variant='btn btn-secondary dropdown-toggle'
                  id='idpdv'
                >
                  {fpdvname}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item eventKey='all'>Tous</Dropdown.Item>
                  {datapdvs.map(option => (
                    <Dropdown.Item eventKey={option.id}>
                      {option.pdv.name} : {option.firstname}
                      {option.lastname}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className='p-2'></div>
            <div className='p-2'></div>
          </div>
        )}
      </div>
      <br />
      <br />
      <div className='row'>
        <div className='col-md-12'>
          {accessmanager.isAdmin() && (
            <>
              <h3 className='font-weight-semibold '>Stock totale</h3>
              <hr class='dashed'></hr>
             {/*  <div className='row'>
                <div className='col-md-6 grid-margin stretch-card average-price-card'>
                  <div className='card texttheme'>
                    <div className='card-body'>
                      <div className='d-flex justify-content-between pb-2 align-items-center'>
                        <Doughnut
                          data={datatest}
                          options={{
                            responsive: true,
                            maintainAspectRatio: true
                          }}
                        ></Doughnut>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-6 grid-margin stretch-card average-price-card'>
                  <div className='card texttheme'>
                    <div className='card-body'>
                      <div className='d-flex justify-content-between pb-2 align-items-center'></div>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className='row'>
                <div className='col-md-6 grid-margin stretch-card average-price-card'>
                  <div className='card texttheme'>
                    <div className='card-body'>
                      <div className='d-flex justify-content-between pb-2 align-items-center'>
                        <h2 className='font-weight-semibold mb-0'>
                          {totalquantity}
                        </h2>
                        <div></div>
                      </div>
                      <div className='d-flex justify-content-between'>
                        <h5 className='font-weight-semibold mb-0'>Cartes</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-6 grid-margin stretch-card average-price-card'>
                  <div className='card texttheme'>
                    <div className='card-body'>
                      <div className='d-flex justify-content-between pb-2 align-items-center'>
                        <h2 className='font-weight-semibold mb-0'>
                          {residuequantity}
                        </h2>
                        <div /* className='icon-holder' */>
                          {/* <img className='icontheme4' src={teamimg} alt='teamimg' /> */}
                        </div>
                      </div>
                      <div className='d-flex justify-content-between'>
                        <h5 className='font-weight-semibold mb-0'>
                          Cartes restantes
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br></br>
            </>
          )}
          {(accessmanager.isAdmin() || (accessmanager.isPdv() && !isfree)) && (
            <>
              {accessmanager.isAdmin() && (
                <h3 className='font-weight-semibold '>
                  Chez les points de vente
                </h3>
              )}
              <hr class='dashed'></hr>
              <div className='row'>
                <div className='col-md-3 grid-margin stretch-card average-price-card'>
                  <div className='card texttheme'>
                    <div className='card-body'>
                      <div className='d-flex justify-content-between pb-2 align-items-center'>
                        <h2 className='font-weight-semibold mb-0'>
                          {reservedquantitypdv}
                        </h2>
                        <div></div>
                      </div>
                      <div className='d-flex justify-content-between'>
                        <h5 className='font-weight-semibold mb-0'>Cartes</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-3 grid-margin stretch-card average-price-card'>
                  <div className='card texttheme'>
                    <div className='card-body'>
                      <div className='d-flex justify-content-between pb-2 align-items-center'>
                        <h2 className='font-weight-semibold mb-0'>
                          {residuetotalsold}
                        </h2>
                        <div /* className='icon-holder' */>
                          {/* <img className='icontheme4' src={teamimg} alt='teamimg' /> */}
                        </div>
                      </div>
                      <div className='d-flex justify-content-between'>
                        <h5 className='font-weight-semibold mb-0'>
                          Cartes restantes
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-3 grid-margin stretch-card average-price-card'>
                  <div className='card texttheme'>
                    <div className='card-body'>
                      <div className='d-flex justify-content-between pb-2 align-items-center'>
                        <h2 className='font-weight-semibold mb-0'>
                          {totalsold}
                        </h2>
                        <div></div>
                      </div>
                      <div className='d-flex justify-content-between'>
                        <h5 className='font-weight-semibold mb-0'>Abonnés</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-3 grid-margin stretch-card average-price-card'>
                  <div className='card texttheme'>
                    <div className='card-body'>
                      <div className='d-flex justify-content-between pb-2 align-items-center'>
                        <h2 className='font-weight-semibold mb-0'>
                          {turnoversold}
                        </h2>
                      </div>
                      <div className='d-flex justify-content-between'>
                        <h5 className='font-weight-semibold mb-0'>
                          Chiffre d'affaires
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br></br>
            </>
          )}
          {(accessmanager.isAdmin() || (accessmanager.isPdv() && isfree)) && (
            <>
              {accessmanager.isAdmin() && (
                <h3 className='font-weight-semibold '>
                  Les abonnements gratuit
                </h3>
              )}
              <hr class='dashed'></hr>
              <div className='row'>
                <div className='col-md-4 grid-margin stretch-card average-price-card'>
                  <div className='card texttheme'>
                    <div className='card-body'>
                      <div className='d-flex justify-content-between pb-2 align-items-center'>
                        <h2 className='font-weight-semibold mb-0'>
                          {reservedquantityfree}
                        </h2>
                        <div></div>
                      </div>
                      <div className='d-flex justify-content-between'>
                        <h5 className='font-weight-semibold mb-0'>Cartes</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-4 grid-margin stretch-card average-price-card'>
                  <div className='card texttheme'>
                    <div className='card-body'>
                      <div className='d-flex justify-content-between pb-2 align-items-center'>
                        <h2 className='font-weight-semibold mb-0'>
                          {residuecardsforfree}
                        </h2>
                        <div /* className='icon-holder' */>
                          {/* <img className='icontheme4' src={teamimg} alt='teamimg' /> */}
                        </div>
                      </div>
                      <div className='d-flex justify-content-between'>
                        <h5 className='font-weight-semibold mb-0'>
                          Cartes restantes
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-4 grid-margin stretch-card average-price-card'>
                  <div className='card texttheme'>
                    <div className='card-body'>
                      <div className='d-flex justify-content-between pb-2 align-items-center'>
                        <h2 className='font-weight-semibold mb-0'>
                          {cardsforfree}
                        </h2>
                        <div></div>
                      </div>
                      <div className='d-flex justify-content-between'>
                        <h5 className='font-weight-semibold mb-0'>Abonnés</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default Dashboard
