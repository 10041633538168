import React, { useState } from 'react'
import { useLayoutEffect, useEffect } from 'react'

import Form from 'react-bootstrap/Form'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Modal from 'react-bootstrap/Modal'
import Footballloader from '../loader/footballloader'
import * as userservice from '../../services/UserService'
import * as categoryservice from '../../services/CategoryService'

import { useParams } from 'react-router'
import { Box, Typography } from '@mui/material'
import { makeStyles } from '@material-ui/core'
import { getStock } from '../../services/StockService'
const useStyles = makeStyles(theme => ({
  value: {
    fontWeight: 500,
    fontSize: '20px  !important',
    marginLeft: '12px  !important',
    marginBottom: '10px !important'
  },
  valueTotal: {
    fontWeight: 500,
    fontSize: '22px  !important',
    marginLeft: '12px  !important',
    marginBottom: '10px !important'
  },
  name: {
    fontSize: '18px  !important',
    color: ' rgb(107, 114, 128)',
    marginBottom: '8px  !important'
  },
  title: {
    fontSize: '18px  !important',
    color: ' rgb(255 23 86)',
    marginBottom: '8px  !important'
  },
  categoryCard: {
    width: 'auto',
    minWidth: '200px',
    border: 'solid',
    padding: '8px',
    margin: '15px',
    borderBottomRightRadius: '25px',
    borderWidth: '1px',
    borderColor: ' #2196f3',
    textAlign: 'center'
  },
  flex: {
    display: 'flex'
  }
}))
const DetailsPDV = () => {
  const classes = useStyles()

  let datastock0 = []
  const [loading, setLoading] = useState(false)
  const [datausers, setDatausers] = useState([])
  const [stock, setStock] = useState({})
  const [ventes, setVentes] = useState(0)
  const [total, setTotal] = useState(0)
  const [reste, setReste] = useState(0)
  const [datacategories, setDatacategories] = useState([])
  const [datastock, setDatastock] = useState([
    { categoryid: '', categoryname: '', qtt: '' }
  ])
  const [state, setState] = useState({
    name: '',
    userid: '',
    address: '',
    telephone: '',
    maxcards: null,
    enable: true
  })

  const getinfcategories = async () => {
    let res = await categoryservice.getallcategoriessmall()
    if (res) {
      // console.log(res)
      setDatacategories(res)
      let table = []
      for (let i = 0; i < res.length; i = i + 3) {
        let table01 = []
        for (let c = i; c < i + 3 && c < res.length; c++) {
          let obj = { categoryid: '', categoryname: '', qtt: 0 }
          obj.categoryid = res[c].id
          obj.name = res[c].name

          table01.push(obj)
          datastock0.push({ categoryid: res[c].id, qtt: 0 })
        }
        table.push(table01)
      }
      // setDatacategories(table)
      setDatastock(datastock0)
    }
  }

  const getinfusers = async () => {
    var res = await userservice.getallusersmall(1, 'pdv')
    if (res) {
      setDatausers(res)
    }
    if (id) {
      const user = await userservice.getUserDetails(id)

      if (user?.data?.code === 0) {
        setState({
          ...state,
          name: user.data.data.name,
          userid: user.data.data.userid,
          address: user.data.data.address,
          telephone: user.data.data.telephone,
          enable: user.data.data.enable
        })
      }
      const stockData = await getStock({ pdvid: id, size: 10 })
     // console.log(stockData)
      if (stockData?.data?.code === 0) {
        setStock(stockData?.data.data)
        let v = 0
        let t = 0

        for (const item of stockData?.data.data.stocks) {
          t = t + item.quantity
          v = v + item.soldnumber
        }
        setVentes(v)
        setTotal(t)
        if (t > v) {
          setReste(t - v)
        }
      }
    }
  }
  const { id } = useParams()
  useEffect(() => {})
  useLayoutEffect(() => {
    getinfusers()
    getinfcategories()
  }, [])
  const getval = id => {
    try {
      return datastock.filter(o => o.categoryid.includes(id))[0].qtt
    } catch (error) {
      //console.log(id)
      return 0
    }
  }
  return (
    <div className='row justify-content-md-center'>
      <div className='col-lg-9 col-md-12 col-sm-12 col-xs-12  grid-margin stretch-card'>
        <div className='card'>
          <div className='card-body'>
            <h3>Details du point de vente</h3>
            <Modal centered show={loading}>
              <Footballloader />
            </Modal>
            <Box className={classes.flex} justifyContent='space-between'>
              <Box>
                <Box className={classes.flex}>
                  {' '}
                  <Typography className={classes.name}>Nom :</Typography>
                  <Typography className={classes.value}>
                    {state.name}
                  </Typography>
                </Box>
                <Box className={classes.flex}>
                  <Typography className={classes.name}>
                    Le numéro de téléphone:
                  </Typography>
                  <Typography className={classes.value}>
                    {state.telephone}
                  </Typography>
                </Box>{' '}
                <Box className={classes.flex}>
                  <Typography className={classes.name}>Adresse:</Typography>
                  <Typography className={classes.value}>
                    {state.address}
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Box className={classes.flex}>
                  <Typography className={classes.name}>
                    Nombre total des cartes:
                  </Typography>
                  <Typography className={classes.valueTotal}>
                    {total}
                  </Typography>
                </Box>{' '}
                <Box className={classes.flex}>
                  <Typography className={classes.name}>
                    Nombre d'abonnements éffectué:
                  </Typography>
                  <Typography className={classes.valueTotal}>
                    {ventes}
                  </Typography>
                </Box>{' '}
                <Box className={classes.flex}>
                  <Typography className={classes.name}>
                    Nombre de cartes restantes:
                  </Typography>
                  <Typography className={classes.valueTotal}>
                    {reste}
                  </Typography>
                </Box>{' '}
              </Box>
            </Box>
            <Container>
              <Typography className={classes.title}>
                {' '}
                Stock selon les catégories:
              </Typography>
              <Box className={classes.flex} flexWrap='wrap'>
                {stock?.stocks?.map(option => (
                  <Row>
                    <Col xxl lg='4'>
                      <Box className={classes.categoryCard}>
                        <Box className={classes.flex}>
                          <Typography className={classes.name}>Nom:</Typography>
                          <Typography className={classes.value}>
                            {datacategories.map(cat => {
                              if (option.categoryId === cat.id) return cat.name
                            })}
                          </Typography>
                        </Box>

                        <Box className={classes.flex}>
                          <Typography className={classes.name}>
                            Total:
                          </Typography>
                          <Typography className={classes.value}>
                            {option.quantity}
                          </Typography>
                        </Box>
                        <Box className={classes.flex}>
                          <Typography className={classes.name}>
                            Vendu:
                          </Typography>
                          <Typography className={classes.value}>
                            {option.soldnumber}
                          </Typography>
                        </Box>
                      </Box>
                    </Col>
                  </Row>
                ))}
              </Box>
            </Container>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DetailsPDV
