import React, { useState } from 'react'
import { useLayoutEffect, useEffect } from 'react'
import Swal from 'sweetalert2'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Modal from 'react-bootstrap/Modal'
import Footballloader from '../loader/footballloader'
import * as categoryservice from '../../services/CategoryService'
import * as userservice from '../../services/UserService'

const Addcategory = () => {
  const [loading, setLoading] = useState(false)
  const [validated, setValidated] = useState(false)
  const [state, setState] = useState({
    name: '',
    prix: '',
    code:'',
    description: ''
  })
  const changeCreds = event => {
    setState({ ...state, [event.target.name]: event.target.value })
  }

  const handleSubmit = async event => {
    event.preventDefault()
    event.stopPropagation()

    setValidated(true)
    const form = event.currentTarget
    if (form.checkValidity() === true) {
      const resadd = await categoryservice.addcategory(state)
      if (resadd !== undefined && resadd !== null) {
        if (resadd.data.code === 0) {
          Swal.fire({
            title: 'success!',
            text: 'Catégorie ajoutée avec succès',
            icon: 'success',
            confirmButtonText: 'OK'
          }).then(result => {
            window.location.href = '#/page/categories'
          })
        } else {
          Swal.fire({
            title: 'Error!',
            text: 'une erreur(' + resadd.data.code + ') se produit avec ',
            icon: 'error',
            confirmButtonText: 'OK'
          })
        }
      }
    }
  }

  useEffect(() => {
    //console.log('useEffect Statements')
  })

  useLayoutEffect(() => {
   
  }, [])
  return (
    <div>
      <div className='row justify-content-md-center'>
        <div className='col-12 grid-margin stretch-card'>
          <div className='card'>
            <div className='card-body'>
              <h3>Ajouter une nouvelle catégorie</h3>
              <Modal centered show={loading}>
                <Footballloader />
              </Modal>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Group
                  as={Row}
                  className='mb-3'
                  controlId='validationCustom01'
                >
                  <Form.Label column sm='2'>
                    Nom
                  </Form.Label>
                  <Col sm='8'>
                    <Form.Control
                      name='name'
                      value={state.name}
                      onChange={event => changeCreds(event)}
                      required
                      type='text'
                      placeholder='Nom'
                    />
                    <Form.Control.Feedback type='invalid'>
                      le nom est obligatoire.
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group
                  as={Row}
                  className='mb-3'
                  controlId='validationCustom01'
                >
                  <Form.Label column sm='2'>
                    Code
                  </Form.Label>
                  <Col sm='8'>
                    <Form.Control
                      name='code'
                      value={state.code}
                      onChange={event => changeCreds(event)}
                      required
                      type='text'
                      placeholder='Code'
                    />
                    <Form.Control.Feedback type='invalid'>
                      le code est obligatoire.
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group
                  as={Row}
                  className='mb-3'
                  controlId='validationCustom01'
                >
                  <Form.Label column sm='2'>
                    Prix
                  </Form.Label>
                  <Col sm='8'>
                    <Form.Control
                      name='prix'
                      value={state.prix}
                      onChange={event => changeCreds(event)}
                      required
                      type='number'
                      min='0'
                      placeholder='Prix'
                    />
                    <Form.Control.Feedback type='invalid'>
                      le prix est obligatoire.
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>


                <Form.Group
                  as={Row}
                  className='mb-3'
                  controlId='validationCustom01'
                >
                  <Form.Label column sm='2'>
                    Description
                  </Form.Label>
                  <Col sm='8'>
                    <Form.Control
                      name='description'
                      value={state.description}
                      onChange={event => changeCreds(event)}
                      type='text'
                      placeholder='Description'
                    />
                    <Form.Control.Feedback type='invalid'>
                      La description est obligatoire.
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Container>
                  <Row className='justify-content-md-center'>
                    <Col xxl lg='3'></Col>
                    <Col xxl lg='3'></Col>

                    <Col xxl lg='3'></Col>
                    <Col xxl lg='3'>
                      <Button className='btn btn-primary-red' type='submit'>
                        ok
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Addcategory
