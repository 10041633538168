import React, { useState } from 'react'
import { useLayoutEffect, useEffect } from 'react'

import Form from 'react-bootstrap/Form'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Modal from 'react-bootstrap/Modal'
import Footballloader from '../loader/footballloader'
import * as userservice from '../../services/UserService'
import * as categoryservice from '../../services/CategoryService'

import { useParams } from 'react-router'
import { Box, Typography } from '@mui/material'
import { makeStyles } from '@material-ui/core'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import * as orderservice from '../../services/OrderServices'

const useStyles = makeStyles(theme => ({
  value: {
    fontWeight: 500,
    fontSize: '20px  !important',
    marginLeft: '12px  !important',
    marginBottom: '10px !important'
  },
  valueTotal: {
    fontWeight: 500,
    fontSize: '22px  !important',
    marginLeft: '12px  !important',
    marginBottom: '10px !important'
  },
  name: {
    fontSize: '18px  !important',
    color: ' rgb(107, 114, 128)',
    marginBottom: '8px  !important'
  },
  title: {
    fontSize: '18px  !important',
    color: ' rgb(255 23 86)',
    marginBottom: '8px  !important'
  },
  orderclientCard: {
    width: '500px',
    minWidth: '200px',
    border: 'solid',
    padding: '8px',
    margin: '15px',
    borderBottomRightRadius: '25px',
    borderWidth: '1px',
    borderColor: ' #2196f3',
    textAlign: 'center'
  },
  flex: {
    display: 'flex'
  }
}))
const Detailsorder = () => {
  const { id } = useParams()
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [orderclient, setOrderclient] = useState([])
  const [order, setOrder] = useState({
    fullName: '',
    cin: '',
    email: '',
    phone: '',
    address: '',
    amount: '',
    quantity: '',
    order_number: '',
    num_card: '',
    order_status: '',
    statusDelivery: '',
    status: ''
  })
  const [nbrcards, setNbrcards] = useState(0)
  const getinfusers = async () => {
    if (id) {
      const order = await orderservice.getorder(id)
      if (order.code === 2202) {
        setOrder({
          ...order,
          fullName: order.data.fullName,
          cin: order.data.cin,
          email: order.data.email,
          phone: order.data.phone,
          address: order.data.address,
          amount: order.data.amount,
          quantity: order.data.quantity,
          order_number: order.data.order_number,
          num_card: order.data.enable,
          order_status: order.data.order_status,
          statusDelivery: order.data.statusDelivery,
          status: order.data.status
        })
        const orderclient = await orderservice.getorderclient(id)
        var tab = []
        if (orderclient.code === 2202) {
          setNbrcards(orderclient.data.rows.length)
          for (var i = 0; i < orderclient.data.rows.length; i++) {
            var ordrclt = {
              id: orderclient.data.rows[i].id,
              fullName: orderclient.data.rows[i].fullName,
              cin: orderclient.data.rows[i].cin,
              email: orderclient.data.rows[i].email,
              phone: orderclient.data.rows[i].phone,
              statusDelivery: orderclient.data.rows[i].statusDelivery,
              category: orderclient.data.rows[i].categoryOnlineId,
              prix: 0
            }
            var ctgonline = await orderservice.getcategoryonline(
              ordrclt.category
            )
            ///console.log(ctgonline)
            if (ctgonline.code === 2202) {
              ordrclt.category = ctgonline.data.name
              ordrclt.prix = ctgonline.data.amount
            }
            tab.push(ordrclt)
          }
          setOrderclient(tab)
        }
      }
    }
  }
  const handleChange = async event => {
    try {
     // console.log(event.target.value)
      if (event.target.value !== order.statusDelivery) {
        const res = await orderservice.updatestatusDelivery(
          id,
          event.target.value
        )

        getinfusers()
      }
    } catch (error) {}
  }
  useEffect(() => {})
  useLayoutEffect(() => {
    getinfusers()
  }, [])
  return (
    <div className='row justify-content-md-center'>
      <div className='col-lg-9 col-md-12 col-sm-12 col-xs-12  grid-margin stretch-card'>
        <div className='card'>
          <div className='card-body'>
            <h3>détails de la commande</h3>
            <Modal centered show={loading}>
              <Footballloader />
            </Modal>
            <Box className={classes.flex} justifyContent='space-between'>
              <Box>
                <Box className={classes.flex}>
                  <Typography className={classes.name}>Nom :</Typography>
                  <Typography className={classes.value}>
                    {order.fullName}
                  </Typography>
                </Box>
                <Box className={classes.flex}>
                  <Typography className={classes.name}>
                    Le numéro de téléphone:
                  </Typography>
                  <Typography className={classes.value}>
                    {order.phone}
                  </Typography>
                </Box>
                <Box className={classes.flex}>
                  <Typography className={classes.name}>Adresse:</Typography>
                  <Typography className={classes.value}>
                    {order.address}
                  </Typography>
                </Box>
                <Box className={classes.flex}>
                  <Typography className={classes.name}>
                    Montant total:
                  </Typography>
                  <Typography className={classes.value}>
                    {order.amount}
                  </Typography>
                </Box>
                <Box className={classes.flex}>
                  <Typography className={classes.name}>
                    État de livraison:
                  </Typography>
                  <Typography className={classes.value}>
                    <FormControl>
                      <RadioGroup
                        aria-labelledby='demo-controlled-radio-buttons-group'
                        name='controlled-radio-buttons-group'
                        value={order.statusDelivery}
                        onChange={handleChange}
                        row
                      >
                        {/*   INITIAL: 0,
                                      CANCELED: 5,
                                      WAITING_FOR_PAYMENT: 1,
                                      IN_PREPARATION: 2,
                                      SHIPPED: 3, //en coours de livraison 
                                      DELIVERED: 4, */}
                        <FormControlLabel
                          value={0}
                          className='success'
                          control={
                            <Radio
                              color={
                                order.statusDelivery === 0
                                  ? 'success'
                                  : 'default'
                              }
                            />
                          }
                          label={
                            order.statusDelivery === 0 ? (
                              <span style={{ color: '#2e7d32' }}>
                                En attente
                              </span>
                            ) : (
                              'En attente'
                            )
                          }
                        />
                        {/* n attente de paiement */}
                        <FormControlLabel
                          value={2}
                          control={
                            <Radio
                              color={
                                order.statusDelivery === 2
                                  ? 'success'
                                  : 'default'
                              }
                            />
                          }
                          label={
                            order.statusDelivery === 2 ? (
                              <span style={{ color: '#2e7d32' }}>
                                En préparation
                              </span>
                            ) : (
                              'En préparation'
                            )
                          }
                        />
                        <FormControlLabel
                          value={3}
                          control={
                            <Radio
                              color={
                                order.statusDelivery === 3
                                  ? 'success'
                                  : 'default'
                              }
                            />
                          }
                          label={
                            order.statusDelivery === 3 ? (
                              <span style={{ color: '#2e7d32' }}>Expédié</span>
                            ) : (
                              'Expédié'
                            )
                          }
                        />
                        <FormControlLabel
                          value={4}
                          control={
                            <Radio
                              color={
                                order.statusDelivery === 4
                                  ? 'success'
                                  : 'default'
                              }
                            />
                          }
                          label={
                            order.statusDelivery === 4 ? (
                              <span style={{ color: '#2e7d32' }}>Livré</span>
                            ) : (
                              'Livré'
                            )
                          }
                        />
                        <FormControlLabel
                          value={5}
                          control={
                            <Radio
                              color={
                                order.statusDelivery === 5
                                  ? 'success'
                                  : 'default'
                              }
                            />
                          }
                          label={
                            order.statusDelivery === 5 ? (
                              <span style={{ color: '#2e7d32' }}>Annulé</span>
                            ) : (
                              'Annulé'
                            )
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Box className={classes.flex}>
                  <Typography className={classes.name}>
                    Nombre total des cartes:
                  </Typography>
                  <Typography className={classes.valueTotal}>
                    {nbrcards}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Container>
              <Typography className={classes.title}>Cartes:</Typography>
              <Box className={classes.flex} flexWrap='wrap'>
                {orderclient?.map(option => (
                  <Row>
                    <Col xxl lg='4'>
                      <Box className={classes.orderclientCard}>
                        <Box className={classes.flex}>
                          <Typography className={classes.name}>
                            Catégorie:
                          </Typography>
                          <Typography className={classes.value}>
                            {option.category}
                          </Typography>
                        </Box>

                        <Box className={classes.flex}>
                          <Typography className={classes.name}>
                            Prix:
                          </Typography>
                          <Typography className={classes.value}>
                            {option.prix}
                          </Typography>
                        </Box>
                        <Box className={classes.flex}>
                          <Typography className={classes.name}>Nom:</Typography>
                          <Typography className={classes.value}>
                            {option.fullName}
                          </Typography>
                        </Box>
                        <Box className={classes.flex}>
                          <Typography className={classes.name}>cin:</Typography>
                          <Typography className={classes.value}>
                            {option.cin}
                          </Typography>
                        </Box>
                        <Box className={classes.flex}>
                          <Typography className={classes.name}>
                            email:
                          </Typography>
                          <Typography className={classes.value}>
                            {option.email}
                          </Typography>
                        </Box>
                        <Box className={classes.flex}>
                          <Typography className={classes.name}>
                            État de livraison:
                          </Typography>
                          <Typography className={classes.value}>
                            {order.statusDelivery === 0
                              ? 'Initiale'
                              : order.statusDelivery === 1
                              ? 'En attente de paiement'
                              : order.statusDelivery === 2
                              ? 'En préparation'
                              : order.statusDelivery === 3
                              ? 'Expédié'
                              : order.statusDelivery === 4
                              ? 'Livré'
                              : order.statusDelivery === 5
                              ? 'Annulé'
                              : 'undefined'}
                          </Typography>
                        </Box>
                      </Box>
                    </Col>
                  </Row>
                ))}
              </Box>
            </Container>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Detailsorder
