import axios from './axios'
import Swal from 'sweetalert2'

import * as localstorage from './LocalStorage'
//{{url}}/api/pdv/get?page=0&size=1
export async function getallpdvpagination (page, size, enable) {
  if (
    localstorage.loadAccess() !== undefined &&
    localstorage.loadAccess() !== null
  ) {
    var filtre = ''
    if (enable && enable !== 'all') {
      filtre += '&enable=' + enable
    }
    let res = await axios
      .get(`/pdv/get?page=` + page + `&size=` + size + filtre)
      .catch(err => {
        return undefined
      })

    return res
  }
}
//{{url}}/api/pdv/findall
export async function findallpdv () {
  if (
    localstorage.loadAccess() !== undefined &&
    localstorage.loadAccess() !== null
  ) {
    let res = await axios.get(`/pdv/findall`).catch(err => {
      return undefined
    })
    return res
  }
}

export async function addpdv (state, user) {
  //console.log(state)
  var enable = state.enable === true ? 1 : 0
  const adresse = state.address ? state.address : user.address
  const telephone = state.telephone ? state.telephone : user.telephone
  var pdv =
    '{      "name": "' +
    state.name +
    '",      "address": "' +
    adresse +
    '",      "telephone": "' +
    telephone +
    '",      "enable":' +
    enable +
    ',      "isfree":' +
    state.isfree +
    ',      "userid": "' +
    user.id +
    '" }'
 // console.log(pdv)
  const instance = axios.create({
    timeout: 100000,
    headers: { Authorization: 'Bearer ' + localstorage.loadAccess() }
  })
  let res = await instance.post(`/pdv/add/`, JSON.parse(pdv)).catch(err => {
    Swal.fire({
      title: 'Error!',
      text: err.message,
      icon: 'error',
      confirmButtonText: 'OK'
    })
    return undefined
  })
  return res
}
export async function updatePdv (state) {
  let enable = state.enable === true ? 1 : 0

  let pdv = {
    name: state.name,
    address: state.address,
    telephone: state.telephone,
    enable: enable,
    userid: state.userid
  }
  //console.log(pdv)
  const instance = axios.create({
    timeout: 100000,
    headers: { Authorization: 'Bearer ' + localstorage.loadAccess() }
  })
  let res = await instance.post(`/pdv/add/`, pdv).catch(err => {
    Swal.fire({
      title: 'Error!',
      text: err.message,
      icon: 'error',
      confirmButtonText: 'OK'
    })
    return undefined
  })
  return res
}
export async function changerstatus (status, id) {
  if (
    localstorage.loadAccess() !== undefined &&
    localstorage.loadAccess() !== null
  ) {
    var body = '{     "enable": "' + status + '"  }'
    let res = await axios
      .patch(`/pdv/updatestatus/` + id, JSON.parse(body))
      .catch(err => {
        return undefined
      })
    return res
  }
}

export async function findbyusr (idusr) {
  if (
    localstorage.loadAccess() !== undefined &&
    localstorage.loadAccess() !== null
  ) {
    let res = await axios.get(`/pdv/getbyusr/` + idusr).catch(err => {
      return undefined
    })
    return res?.data?.data
  }
}

export async function isfreebyusr (idusr) {
  if (
    localstorage.loadAccess() !== undefined &&
    localstorage.loadAccess() !== null
  ) {
    let res = await axios.get(`/pdv/isfree/` + idusr).catch(err => {
      return undefined
    })
    return res?.data?.data
  }
}
export async function changerisfree (id,isfree) {
  if (
    localstorage.loadAccess() !== undefined &&
    localstorage.loadAccess() !== null
  ) {
    var body = '{     "isfree": ' + isfree + '  }'
    let res = await axios
      .patch(`/pdv/updateisfree/` + id, JSON.parse(body))
      .catch(err => {
        return undefined
      })
    return res
  }
}