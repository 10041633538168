import * as localstorage from './LocalStorage'

export const isAdmin = () => {
  const roles = localstorage.loadRoles()
  var res = false
  roles.forEach(element => {
    if (element.toLowerCase().includes('admin') === true) {
      res = true
    }
  })
  return res
}
export const isPdv = () => {
  const roles = localstorage.loadRoles()
  var res = false
  roles.forEach(element => {
    if (element.toLowerCase().includes('pdv') === true) {
      res = true
    }
  })
  return res
}

export const isUser = () => {
  const roles = localstorage.loadRoles()
  var res = false
  roles.forEach(element => {
    if (element.toLowerCase().includes('user') === true) {
      res = true
    }
  })
  return res
}
