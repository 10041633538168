import axios from './axios'
import Swal from 'sweetalert2'
import * as accessmanager from './AccessManager'
import * as localstorage from './LocalStorage'
import download from 'js-file-download'
//{{url}}/api/card/get?page=0&size=1
export async function getallcardpagination (
  page,
  size,
  imei,
  code,
  affected,
  status,
  categoryid,
  pdvid,
  startdate,
  enddate,
  serialnumber
) {
  if (
    localstorage.loadAccess() !== undefined &&
    localstorage.loadAccess() !== null
  ) {
    var filtre = ''
    if (imei && imei !== 'all') {
      filtre += '&imei=' + imei
    }
    if (code && code !== 'all') {
      filtre += '&code=' + code
    }
    if (affected && affected !== 'all') {
      filtre += '&affected=' + affected
    }
    if (status && status !== 'all') {
      filtre += '&status=' + status
    }
    if (categoryid && categoryid !== 'all') {
      filtre += '&categoryid=' + categoryid
    }
    if (accessmanager.isPdv()) {
      filtre += '&updatedby=' + localstorage.loadId()
    }
    if (accessmanager.isAdmin() && pdvid && pdvid !== 'all') {
      filtre += '&updatedby=' + pdvid
    }
    if (startdate && startdate !== 'all') {
      filtre += '&startdate=' + startdate
    }
    if (enddate && enddate !== 'all') {
      filtre += '&enddate=' + enddate
    }
    if (serialnumber && serialnumber !== 'all') {
      filtre += '&serialnumber=' + serialnumber
    }
    
    let res = await axios
      .get(`/card/get?page=` + page + `&size=` + size + filtre)
      .catch(err => {
        return undefined
      })

    return res
  }
}
//{{url}}/api/card/findall
export async function findallcard () {
  if (
    localstorage.loadAccess() !== undefined &&
    localstorage.loadAccess() !== null
  ) {
    let res = await axios.get(`/card/findall`).catch(err => {
      return undefined
    })
    return res
  }
}

export async function addcard (state) {
  var card =
    '{     "idnfc": "' +
    state.idnfc +
    '",      "serialnumber": "' +
    state.serialnumber +
    '"'
  if (state.email && state.email !== null && state.email !== undefined) {
    card += ',  "imei": "' + state.imei + '"'
  }
  if (state.email && state.email !== null && state.email !== undefined) {
    card += ',      "code": "' + state.code
  }
  card += ',      "categoryid": "' + state.categoryid + '" }'
  //console.log(card)
  const instance = axios.create({
    timeout: 100000,
    headers: { Authorization: 'Bearer ' + localstorage.loadAccess() }
  })
  let res = await instance.post(`/card/add/`, JSON.parse(card)).catch(err => {
    Swal.fire({
      title: 'Error!',
      text: err.message,
      icon: 'error',
      confirmButtonText: 'OK'
    })
    return undefined
  })
  return res
}
export async function getallcardssmall (affected, status, categoryid) {
  if (
    localstorage.loadAccess() !== undefined &&
    localstorage.loadAccess() !== null
  ) {
    var filtre = ''
    if (categoryid && categoryid !== 'all') {
      filtre += '&categoryid=' + categoryid
    }
    let res = await axios
      .get(
        `/card/findallsmall?affected=` + affected + '&status=' + status + filtre
      )
      .catch(err => {
        return []
      })
    return res.data.data.rows
  }
  return []
}

export async function importfile (file) {
  var var_card = '{     "myfile": "' + file + '" }'
  const instance = axios.create({
    timeout: 100000,
    headers: { Authorization: 'Bearer ' + localstorage.loadAccess() }
  })
  let res = await instance
    .post(`/file/add`, JSON.parse(var_card))
    .catch(err => {
      Swal.fire({
        title: 'Error!',
        text: err.message,
        icon: 'error',
        confirmButtonText: 'OK'
      })
      return undefined
    })
  return res
}
export async function changerstatus (status, id) {
  if (
    localstorage.loadAccess() !== undefined &&
    localstorage.loadAccess() !== null
  ) {
    var body = '{     "status": "' + status + '"  }'
    let res = await axios
      .patch(`/card/updatestatus/` + id, JSON.parse(body))
      .catch(err => {
        return undefined
      })
    return res
  }
}

export async function downloaddd (
  affected,
  status,
  categoryid,
  pdvid,
  startdate,
  enddate
) {
  try {
    if (
      localstorage.loadAccess() !== undefined &&
      localstorage.loadAccess() !== null
    ) {
      var filtre = ''

      if (affected && affected !== 'all') {
        filtre += filtre ? '&' : '?'
        filtre += 'affected=' + affected
      }
      if (status && status !== 'all') {
        filtre += filtre ? '&' : '?'
        filtre += 'status=' + status
      }
      if (categoryid && categoryid !== 'all') {
        filtre += filtre ? '&' : '?'
        filtre += 'categoryid=' + categoryid
      }
      if (accessmanager.isPdv()) {
        filtre += filtre ? '&' : '?'
        filtre += 'updatedby=' + localstorage.loadId()
      }
      if (accessmanager.isAdmin() && pdvid && pdvid !== 'all') {
        filtre += filtre ? '&' : '?'
        filtre += 'updatedby=' + pdvid
      }
      if (startdate && startdate !== 'all') {
        filtre += filtre ? '&' : '?'
        filtre += 'startdate=' + startdate
      }
      if (enddate && enddate !== 'all') {
        filtre += filtre ? '&' : '?'
        filtre += 'enddate=' + enddate
      }
      let res = await axios.get(`/card/download` + filtre).catch(err => {
        return undefined
      })
      if (!res.data.code) {
        download(res.data, 'List_cartes.csv')
        return 0
      } else {
        return -2
      }
    }
  } catch (error) {}
  return -1
}
