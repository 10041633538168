import axios from './axios'
import Swal from 'sweetalert2'
import * as localstorage from './LocalStorage'
export async function addstock (pdvid, categoryid, quantity) {
  /*  "pdvid": "",
    "categoryid": "",
    "quantity": 0 */
  let stock =
    '{      "pdvid": "' +
    pdvid +
    '",      "categoryid": "' +
    categoryid +
    '",      "quantity": ' +
    quantity +
    ' }'
  //console.log(stock)
  const instance = axios.create({
    timeout: 100000,
    headers: { Authorization: 'Bearer ' + localstorage.loadAccess() }
  })
  let res = await instance.post(`/stock/add/`, JSON.parse(stock)).catch(err => {
    Swal.fire({
      title: 'Error!',
      text: err.message,
      icon: 'error',
      confirmButtonText: 'OK'
    })
    return undefined
  })
  return res
}
export async function updateStock (pdvid, categoryid, quantity) {
  /*  "pdvid": "",
    "categoryid": "",
    "quantity": 0 */
  let stock = { pdvid: pdvid, categoryid: categoryid, quantity: quantity }
  //console.log(stock)
  const instance = axios.create({
    timeout: 100000,
    headers: { Authorization: 'Bearer ' + localstorage.loadAccess() }
  })
  let res = await instance.patch(`/stock/updatestock/`, stock).catch(err => {
    Swal.fire({
      title: 'Error!',
      text: err.message,
      icon: 'error',
      confirmButtonText: 'OK'
    })
    return undefined
  })
  return res.data
}
export async function decreaseStock (pdvid, categoryid, quantity) {
  /*  "pdvid": "",
    "categoryid": "",
    "quantity": 0 */
  let stock = { pdvid: pdvid, categoryid: categoryid, quantity: quantity }
  //console.log(stock)
  const instance = axios.create({
    timeout: 100000,
    headers: { Authorization: 'Bearer ' + localstorage.loadAccess() }
  })
  let res = await instance.patch(`/stock/decrease/`, stock).catch(err => {
    Swal.fire({
      title: 'Error!',
      text: err.message,
      icon: 'error',
      confirmButtonText: 'OK'
    })
    return undefined
  })
  return res.data
}
export async function getStock (params) {
  const instance = axios.create({
    timeout: 100000,
    headers: { Authorization: 'Bearer ' + localstorage.loadAccess() }
  })
  let res = await instance.get(`/stock/get`, { params: params }).catch(err => {
    Swal.fire({
      title: 'Error!',
      text: err.message,
      icon: 'error',
      confirmButtonText: 'OK'
    })
    return undefined
  })
  return res
}
