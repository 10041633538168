import axios from './axios'
import Swal from 'sweetalert2'
import * as localstorage from './LocalStorage'
import download from 'js-file-download'
//{{url}}/api/accessevent/get?page=0&size=1
export async function getallaccesseventpagination (
  page,
  size,
  startdate,
  enddate,
  code,
  name
) {
  if (
    localstorage.loadAccess() !== undefined &&
    localstorage.loadAccess() !== null
  ) {
    var filtre = ''
    if (startdate && startdate !== 'all') {
      filtre += '&startdate=' + startdate
    }
    if (enddate && enddate !== 'all') {
      filtre += '&enddate=' + enddate
    }
    if (code && code !== 'all') {
      filtre += '&code=' + code
    }
    if (name && name !== 'all') {
      filtre += '&name=' + name
    }

    let res = await axios
      .get(`/accessevent/get?page=` + page + `&size=` + size + filtre)
      .catch(err => {
        return undefined
      })

    return res
  }
}
//{{url}}/api/accessevent/findall
export async function findallaccessevent () {
  if (
    localstorage.loadAccess() !== undefined &&
    localstorage.loadAccess() !== null
  ) {
    let res = await axios.get(`/accessevent/findall`).catch(err => {
      return undefined
    })
    return res
  }
}

//{{url}}/api/accessdata/get?page=0&size=1
export async function getallaccessdatapagination (
  page,
  size,
  accesseventid,
  cin,
  phone,
  serialnumber,
  access
) {
  if (
    localstorage.loadAccess() !== undefined &&
    localstorage.loadAccess() !== null
  ) {
    var filtre = ''
    if (accesseventid && accesseventid !== 'all') {
      filtre += '&accesseventid=' + accesseventid
    }
    if (cin && cin !== 'all') {
      filtre += '&cin=' + cin
    }
    if (phone && phone !== 'all') {
      filtre += '&phone=' + phone
    }
    if (serialnumber && serialnumber !== 'all') {
      filtre += '&serialnumber=' + serialnumber
    }
    if (access && access !== 'all') {
      filtre += '&access=' + access
    }
    let res = await axios
      .get(`/accessdata/get?page=` + page + `&size=` + size + filtre)
      .catch(err => {
        return undefined
      })

    return res
  }
}
//{{url}}/api/accessdata/findall
export async function findallaccessdata () {
  if (
    localstorage.loadAccess() !== undefined &&
    localstorage.loadAccess() !== null
  ) {
    let res = await axios.get(`/accessdata/findall`).catch(err => {
      return undefined
    })
    return res
  }
}
export async function importfile (file) {
  var var_card = '{ "file": "' + file + '","imei":"0000" }'
  const instance = axios.create({
    timeout: 100000,
    headers: { Authorization: 'Bearer ' + localstorage.loadAccess() }
  })
  let res = await instance
    .post(`/upload/statusdata`, JSON.parse(var_card))
    .catch(err => {
      Swal.fire({
        title: 'Error!',
        text: err.message,
        icon: 'error',
        confirmButtonText: 'OK'
      })
      return undefined
    })
  return res
}

export async function downloaddd (accesseventid) {
  try {
    if (
      localstorage.loadAccess() !== undefined &&
      localstorage.loadAccess() !== null
    ) {
      let res = await axios
        .get(`/accessevent/download?accesseventid=` + accesseventid)
        .catch(err => {
          return undefined
        })
      if (!res.data.code) {
        download(res.data, 'List_eventdata_.csv')
        return 0
      } else {
        return -2
      }
    }
  } catch (error) {}
  return -1
}
