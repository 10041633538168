import React, { useState } from 'react'
import { useLayoutEffect, useEffect } from 'react'
import Swal from 'sweetalert2'
import Form from 'react-bootstrap/Form'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { useParams } from 'react-router'
import { Box, Typography } from '@mui/material'
import { makeStyles } from '@material-ui/core'
import Footballloader from '../loader/footballloader'
import * as subscriberservice from '../../services/SubscriberService'
import * as cardservice from '../../services/CardServices'
import * as categoryservice from '../../services/CategoryService'
const useStyles = makeStyles(theme => ({
  value: {
    fontWeight: 500,
    fontSize: '20px  !important',
    marginLeft: '12px  !important',
    marginBottom: '10px !important'
  },
  valueTotal: {
    fontWeight: 500,
    fontSize: '22px  !important',
    marginLeft: '12px  !important',
    marginBottom: '10px !important'
  },
  name: {
    fontSize: '18px  !important',
    color: ' rgb(107, 114, 128)',
    marginBottom: '8px  !important'
  },
  title: {
    fontSize: '18px  !important',
    color: ' rgb(255 23 86)',
    marginBottom: '8px  !important'
  },
  categoryCard: {
    width: 'auto',
    minWidth: '200px',
    border: 'solid',
    padding: '8px',
    margin: '15px',
    borderBottomRightRadius: '25px',
    borderWidth: '1px',
    borderColor: ' #2196f3',
    textAlign: 'center'
  },
  flex: {
    display: 'flex'
  }
}))
const Editcard = () => {
  const classes = useStyles()
  const { id } = useParams()
  const [loading, setLoading] = useState(true)
  const [validated, setValidated] = useState(false)
  const [infsubscriber, setSubscriber] = useState({
    firstname: '',
    lastname: '',
    telephone: null,
    address: '',
    cin: ''
  })
  const [state, setState] = useState({
    oldserialnumber: '',
    newserialnumber: '',
    telephone: null,
    cin: ''
  })
  const changeCreds = event => {
    setState({ ...state, [event.target.name]: event.target.value })
  }

  const handleSubmit = async event => {
    event.preventDefault()
    event.stopPropagation()

    setValidated(true)
    const form = event.currentTarget
    if (form.checkValidity() === true) {
      const res = await subscriberservice.updatesubscription(state)
      setValidated(false)
      if (res && res !== null && res !== undefined) {
        if (res.code === 0) {
          Swal.fire({
            title: 'success!',
            text: 'Abonné modifié avec succès',
            icon: 'success',
            confirmButtonText: 'OK'
          }).then(result => {
            window.location.href = '#/page/subscribers'
          })
        } else if (res.code === -8) {
          Swal.fire({
            title: 'error!',
            text: "L'ancienne carte n'est pas pour cette abonnement",
            icon: 'error',
            confirmButtonText: 'OK'
          })
        } else if (res.code === -70) {
          Swal.fire({
            title: 'Error!',
            text: 'la nouvelle carte est affectée à un autre abonnement',
            icon: 'error',
            confirmButtonText: 'OK'
          })
        } else if (res.code === -3) {
          Swal.fire({
            title: 'Error!',
            text:
              "L'ancienne carte est introuvable, vérifiez le numéro de série",
            icon: 'error',
            confirmButtonText: 'OK'
          })
        } else if (res.code === -6) {
          Swal.fire({
            title: 'Error!',
            text:
              'la nouvelle carte est introuvable, vérifiez le numéro de série',
            icon: 'error',
            confirmButtonText: 'OK'
          })
        } else if (
          res.code === -9 ||
          res.code === -10 ||
          res.code === -11 ||
          res.code === -12
        ) {
          Swal.fire({
            title: 'Error!',
            text: "Vous n'avez pas un stock",
            icon: 'error',
            confirmButtonText: 'OK'
          })
        }
      } else {
        Swal.fire({
          title: 'Error!',
          text: 'Une erreur se produite',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      }
    }
  }
  const getsubscriber = async () => {
    const res = await subscriberservice.getsubscriber(id)

    if (res.code === 0) {
      setLoading(false)
      const sbr = {
        firstname: res.data.firstname ? res.data.firstname : '',
        lastname: res.data.lastname ? res.data.lastname : '',
        telephone: res.data.telephone ? res.data.telephone : '',
        address: res.data.address ? res.data.address : '',
        cin: res.data.cin ? res.data.cin : ''
      }
      setSubscriber(sbr)
      const inf = {
        oldserialnumber: '',
        newserialnumber: '',
        telephone: res.data.telephone ? res.data.telephone : '',
        cin: res.data.cin ? res.data.cin : ''
      }
      setState(inf)
    }
  }
  useEffect(() => {}, [])

  useLayoutEffect(() => {
    getsubscriber()
  }, [])

  return (
    <div>
      <div className='row justify-content-md-center'>
        <div className='col-12 grid-margin stretch-card'>
          <div className='card'>
            <div className='card-body'>
              <h3>Changer l'abonnement</h3>
              <br/>
              <br/>
              <Modal centered show={loading}>
                <Footballloader />
              </Modal>
              <Box className={classes.flex} justifyContent='space-between'>
                <Box>
                  <Box className={classes.flex}>
                    <Typography className={classes.name}>Nom :</Typography>
                    <Typography className={classes.value}>
                      {infsubscriber.firstname} {infsubscriber.lastname}
                    </Typography>
                  </Box>
                  <Box className={classes.flex}>
                    <Typography className={classes.name}>
                      Le numéro de téléphone:
                    </Typography>
                    <Typography className={classes.value}>
                      {infsubscriber.telephone}
                    </Typography>
                  </Box>
                  <Box className={classes.flex}>
                    <Typography className={classes.name}>Adresse:</Typography>
                    <Typography className={classes.value}>
                      {infsubscriber.address}
                    </Typography>
                  </Box>
                  <Box className={classes.flex}>
                    <Typography className={classes.name}>CIN:</Typography>
                    <Typography className={classes.value}>
                      {infsubscriber.cin}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <br />
              <br />
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Group
                  as={Row}
                  className='mb-3'
                  controlId='validationCustom01'
                >
                  <Form.Label column sm='3'>
                    Numéro de série de l'ancienne carte
                  </Form.Label>
                  <Col sm='6'>
                    <Form.Control
                      name='oldserialnumber'
                      value={state.oldserialnumber}
                      onChange={event => changeCreds(event)}
                      required
                      type='text'
                      placeholder="Numéro de série de l'ancienne carte"
                    />
                    <Form.Control.Feedback type='invalid'>
                      Le numéro de série de l'ancienne carte est obligatoire.
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  className='mb-3'
                  controlId='validationCustom01'
                >
                  <Form.Label column sm='3'>
                    Numéro de série de la nouvelle carte
                  </Form.Label>
                  <Col sm='6'>
                    <Form.Control
                      name='newserialnumber'
                      value={state.newserialnumber}
                      onChange={event => changeCreds(event)}
                      required
                      type='text'
                      placeholder='Numéro de série de la nouvelle carte'
                    />
                    <Form.Control.Feedback type='invalid'>
                      Le Numéro de série de la nouvelle carteest obligatoire.
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Container>
                  <Row className='justify-content-md-center'>
                    <Col xxl lg='3'></Col>
                    <Col xxl lg='3'></Col>

                    <Col xxl lg='3'></Col>
                    <Col xxl lg='3'>
                      <Button className='btn btn-primary-red' type='submit'>
                        ok
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Editcard
