import React, { useState } from 'react'
import { useLayoutEffect, useEffect } from 'react'
import Swal from 'sweetalert2'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { makeStyles, TextField } from '@material-ui/core'
import Autocomplete from '@mui/material/Autocomplete'
import Modal from 'react-bootstrap/Modal'
import { useParams } from 'react-router'
import Footballloader from '../loader/footballloader'
import * as subscriberservice from '../../services/SubscriberService'
import * as cardservice from '../../services/CardServices'
import * as categoryservice from '../../services/CategoryService'

const Editsubscriber = () => {
  const { id } = useParams()
  const [loading, setLoading] = useState(true)
  const [validated, setValidated] = useState(false)
  const [state, setState] = useState({
    firstname: '',
    lastname: '',
    telephone: null,
    address: '',
    cin: ''
  })
  const changeCreds = event => {
    setState({ ...state, [event.target.name]: event.target.value })
  }

  const handleSubmit = async event => {
    event.preventDefault()
    event.stopPropagation()

    setValidated(true)
    const form = event.currentTarget
    if (form.checkValidity() === true) {
      const res = await subscriberservice.updatesubscriber(id, state)
      setValidated(false)
      if (res && res !== null && res !== undefined) {
        if (res.code === 0) {
          Swal.fire({
            title: 'success!',
            text: 'Abonné modifié avec succès',
            icon: 'success',
            confirmButtonText: 'OK'
          }).then(result => {
            window.location.href = '#/page/subscribers'
          })
        } else if (res.code === -2) {
          Swal.fire({
            title: 'error!',
            text: 'Le numéro CIN doit être unique',
            icon: 'error',
            confirmButtonText: 'OK'
          })
        } else if (res.code === -3) {
          Swal.fire({
            title: 'Error!',
            text: 'Le numéro de téléphone doit être unique',
            icon: 'error',
            confirmButtonText: 'OK'
          })
        } else {
          Swal.fire({
            title: 'Error!',
            text: 'Une erreur se produite',
            icon: 'error',
            confirmButtonText: 'OK'
          })
        }
      } else {
        Swal.fire({
          title: 'Error!',
          text: 'Une erreur se produite',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      }
    }
  }
  const getsubscriber = async () => {
    const res = await subscriberservice.getsubscriber(id)

    if (res.code === 0) {
      setLoading(false)
      const sbr = {
        firstname: res.data.firstname ? res.data.firstname : '',
        lastname: res.data.lastname ? res.data.lastname : '',
        telephone: res.data.telephone ? res.data.telephone : '',
        address: res.data.address ? res.data.address : '',
        cin: res.data.cin ? res.data.cin : ''
      }
      setState(sbr)
    }
  }
  useEffect(() => {}, [])

  useLayoutEffect(() => {
    getsubscriber()
  }, [])

  return (
    <div>
      <div className='row justify-content-md-center'>
        <div className='col-12 grid-margin stretch-card'>
          <div className='card'>
            <div className='card-body'>
              <h3>Modifier un Abonné</h3>
              <Modal centered show={loading}>
                <Footballloader />
              </Modal>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Group
                  as={Row}
                  className='mb-3'
                  controlId='validationCustom01'
                >
                  <Form.Label column sm='2'>
                    Nom
                  </Form.Label>
                  <Col sm='8'>
                    <Form.Control
                      name='firstname'
                      value={state.firstname}
                      onChange={event => changeCreds(event)}
                      required
                      type='text'
                      placeholder='Nom'
                    />
                    <Form.Control.Feedback type='invalid'>
                      Le nom est obligatoire.
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  className='mb-3'
                  controlId='validationCustom01'
                >
                  <Form.Label column sm='2'>
                    Prenom
                  </Form.Label>
                  <Col sm='8'>
                    <Form.Control
                      name='lastname'
                      value={state.lastname}
                      onChange={event => changeCreds(event)}
                      required
                      type='text'
                      placeholder='Prenom'
                    />
                    <Form.Control.Feedback type='invalid'>
                      Le Prenom est obligatoire.
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group
                  as={Row}
                  className='mb-3'
                  controlId='validationCustom01'
                >
                  <Form.Label column sm='2'>
                    Le numéro du téléphone
                  </Form.Label>
                  <Col sm='8'>
                    <Form.Control
                      name='telephone'
                      value={state.telephone}
                      onChange={event => changeCreds(event)}
                      required
                      type='text'
                      placeholder='Numéro du téléphone'
                    />
                    <Form.Control.Feedback type='invalid'>
                      Le numéro du téléphone est obligatoire.
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  className='mb-3'
                  controlId='validationCustom01'
                >
                  <Form.Label column sm='2'>
                    Adresse
                  </Form.Label>
                  <Col sm='8'>
                    <Form.Control
                      name='address'
                      value={state.address}
                      onChange={event => changeCreds(event)}
                      required
                      type='text'
                      placeholder='Adresse'
                    />
                    <Form.Control.Feedback type='invalid'>
                      L'adresse est obligatoire.
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  className='mb-3'
                  controlId='validationCustom01'
                >
                  <Form.Label column sm='2'>
                    Numéro CIN
                  </Form.Label>
                  <Col sm='8'>
                    <Form.Control
                      name='cin'
                      value={state.cin}
                      onChange={event => changeCreds(event)}
                      required
                      type='text'
                      placeholder="Numéro carte d'identité nationale"
                    />
                    <Form.Control.Feedback type='invalid'>
                      Le numéro CIN est obligatoire.
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Container>
                  <Row className='justify-content-md-center'>
                    <Col xxl lg='3'></Col>
                    <Col xxl lg='3'></Col>

                    <Col xxl lg='3'></Col>
                    <Col xxl lg='3'>
                      <Button className='btn btn-primary-red' type='submit'>
                        ok
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Editsubscriber
